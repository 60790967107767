import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { IonicSelectableComponent } from 'ionic-selectable';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { first, tap } from 'rxjs/operators';
import {
    TransportLocation,
    transportLocationCreated,
    TransportLocationEntityService,
} from '@sansys/crosslib';
import { Subscription } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { AlertController } from '@ionic/angular';
import { AlertButton } from '@ionic/core/dist/types/components/alert/alert-interface';
import { NgxPermissionsService } from 'ngx-permissions';
import { translate } from '@ngneat/transloco';

@Component({
    selector: 'sys-transport-location-picker[currentTransportLocation]',
    templateUrl: './transport-location-picker.component.html',
    styleUrls: ['./transport-location-picker.component.scss'],
})
export class TransportLocationPickerComponent implements OnInit, OnDestroy {
    @Output()
    transportLocationIdSelected = new EventEmitter<TransportLocation>();
    @Input() currentTransportLocation?: TransportLocation;
    @Input() placeholder = 'Start-/Zielort wählen';
    @ViewChild('transportLocationComponent')
    transportLocationComponent!: IonicSelectableComponent;

    private subscription = new Subscription();

    transportLocations: TransportLocation[] = [];
    transportLocationForm: FormGroup;
    companyNameControl: FormControl;
    streetControl: FormControl;
    zipControl: FormControl;
    cityControl: FormControl;
    isManualControl: FormControl;
    page = 1;
    loadingAmount = 20;
    amount?: number;
    dataSubscription?: Subscription;
    canEdit = false;
    canAdd = false;
    canDelete = false;

    constructor(
        private transportLocationEntityService: TransportLocationEntityService,
        private formBuilder: FormBuilder,
        private action$: Actions,
        private permissionService: NgxPermissionsService,
        private alertController: AlertController
    ) {
        this.companyNameControl = this.formBuilder.control(
            null,
            Validators.required
        );
        this.streetControl = this.formBuilder.control(
            null,
            Validators.required
        );
        this.zipControl = this.formBuilder.control(null, Validators.required);
        this.cityControl = this.formBuilder.control(null, Validators.required);
        this.isManualControl = this.formBuilder.control(false);
        this.transportLocationForm = this.formBuilder.group({
            companyName: this.companyNameControl,
            street: this.streetControl,
            zip: this.zipControl,
            city: this.cityControl,
            isManual: this.isManualControl,
        });
    }

    private setTransportLocationAndClose(
        transportLocation: TransportLocation
    ): void {
        if (this.transportLocationComponent.isOpened) {
            this.currentTransportLocation = transportLocation;
            this.transportLocationIdSelected.emit(transportLocation);
            this.transportLocationComponent.close();
        }
    }

    ngOnInit(): void {
        this.permissionService
            .hasPermission(['transportLocations|EDIT', 'admin'])
            .then((perm) => (this.canEdit = perm));
        this.permissionService
            .hasPermission(['transportLocations|CREATE', 'admin'])
            .then((perm) => (this.canAdd = perm));
        this.permissionService
            .hasPermission(['transportLocations|DELETE', 'admin'])
            .then((perm) => (this.canDelete = perm));

        if (this.currentTransportLocation?.id) {
            this.transportLocationEntityService
                .getByKey(this.currentTransportLocation.id)
                .pipe(
                    first(),
                    tap((transportLocation) => {
                        this.currentTransportLocation = transportLocation;
                    })
                )
                .subscribe();
        }

        this.subscription.add(
            this.action$
                .pipe(
                    ofType(transportLocationCreated),
                    tap((action) => {
                        this.transportLocationEntityService.addOneToCache(
                            action.transportLocation
                        );
                        this.setTransportLocationAndClose(
                            action.transportLocation
                        );
                    })
                )
                .subscribe()
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onAddTransportLocation(event: {
        component: IonicSelectableComponent;
    }): void {
        // Clean form.
        this.companyNameControl.reset();
        this.streetControl.reset();
        this.zipControl.reset();
        this.cityControl.reset();
        this.isManualControl.reset();

        // Copy search text to transportLocation name field, so
        // user doesn't have to type again.
        this.companyNameControl.setValue(event.component.searchText);

        // Show form.
        event.component.showAddItemTemplate();
    }

    onEditTransportLocation(event: {
        component: IonicSelectableComponent;
        item: TransportLocation;
    }): void {
        // Fill form.
        this.companyNameControl.setValue(event.item.companyName);
        this.streetControl.setValue(event.item.street);
        this.zipControl.setValue(event.item.zip);
        this.cityControl.setValue(event.item.city);

        // Show form.
        event.component.showAddItemTemplate();
    }

    // onDeleteTransportLocation(event: {
    //     component: IonicSelectableComponent;
    //     item: TransportLocation;
    // }): void {
    //     // Delete transportLocation from storage.
    //     this.transportLocationEntityService.delete(event.item);
    //
    //     // Delete transportLocation from list.
    //     event.component.deleteItem(event.item);
    // }

    private async askIfDuplicateAddressIsCorrect(
        newTransportLocation: TransportLocation,
        existingTransportLocations: TransportLocation[]
    ): Promise<void> {
        const alert = await this.alertController.create({
            header: translate('Mögliche Duplikate gefunden'),
            message: translate('Verwenden oder neu anlegen?'),
            buttons: [],
        });

        const buttons: AlertButton[] = [
            {
                text: translate('Abbrechen'),
                role: 'cancel',
            },
        ];

        existingTransportLocations.forEach((existingTransportLocation) => {
            buttons.push({
                text:
                    existingTransportLocation.companyName ||
                    existingTransportLocation.firstName +
                        ' ' +
                        existingTransportLocation.lastName,
                handler: () => {
                    this.setTransportLocationAndClose(
                        existingTransportLocation
                    );
                },
            });
        });

        buttons.push({
            text: translate('Neu Anlegen'),
            handler: () => {
                this.transportLocationEntityService
                    .add(newTransportLocation)
                    .subscribe((newTransportLoc) => {
                        this.setTransportLocationAndClose(newTransportLoc);
                    });
            },
        });

        alert.buttons = buttons;

        await alert.present();
    }

    addTransportLocation(): void {
        const transportLocation: TransportLocation = {
            companyName: this.companyNameControl.value,
            street: this.streetControl.value,
            zip: this.zipControl.value,
            city: this.cityControl.value,
            isManual: this.isManualControl.value,
            separateInvoiceAddress: false,
            tariffs: [],
        };

        const foundTransportLocations: TransportLocation[] = [];
        this.transportLocations.forEach((transportL) => {
            if (
                transportL.street?.toLowerCase() ===
                    transportLocation.street?.toLowerCase() &&
                transportL.zip === transportLocation.zip
            ) {
                foundTransportLocations.push(transportL);
            }
        });

        if (foundTransportLocations.length > 0) {
            this.askIfDuplicateAddressIsCorrect(
                transportLocation,
                foundTransportLocations
            );
            return;
        }

        this.transportLocationEntityService
            .add(transportLocation)
            .subscribe((newTransportLocation) => {
                this.setTransportLocationAndClose(newTransportLocation);
            });
    }

    saveTransportLocation(transportLocation: TransportLocation): void {
        const editedTransportLocation = { ...transportLocation };
        editedTransportLocation.companyName = this.companyNameControl.value;
        editedTransportLocation.street = this.streetControl.value;
        editedTransportLocation.zip = this.zipControl.value;
        editedTransportLocation.city = this.cityControl.value;
        this.transportLocationEntityService
            .update(editedTransportLocation)
            .subscribe(() =>
                this.searchItems({
                    component: this.transportLocationComponent,
                    text: editedTransportLocation.companyName ?? '',
                })
            );
        // Show list.
        this.transportLocationComponent.hideAddItemTemplate();
    }

    setTransportLocation(): void {
        this.transportLocationIdSelected.emit(this.currentTransportLocation);
    }

    searchItems(event?: {
        component: IonicSelectableComponent;
        text: string;
    }): void {
        let text = '';
        if (event) {
            text = event.text.trim().toLowerCase();
        }
        this.transportLocationComponent.startSearch();

        // Close any running subscription.
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }

        if (!text) {
            // Close any running subscription.
            if (this.dataSubscription) {
                this.dataSubscription.unsubscribe();
            }

            this.transportLocationEntityService
                .getWithQuery({
                    start: '0',
                    amount: this.loadingAmount.toString(),
                    search: '',
                })
                .subscribe((transportLocations) => {
                    this.transportLocations = transportLocations;
                    this.transportLocationComponent.items = transportLocations;
                    // Enable and start infinite scroll from the beginning.
                    this.page = 1;
                    this.transportLocationComponent.endSearch();
                    this.transportLocationComponent.enableInfiniteScroll();
                });

            return;
        }

        this.dataSubscription = this.transportLocationEntityService
            .getWithQuery({
                start: '0',
                amount: this.loadingAmount.toString(),
                search: text,
            })
            .subscribe((transportLocations) => {
                // Subscription will be closed when unsubscribed manually.
                if (this.dataSubscription && this.dataSubscription.closed) {
                    return;
                }

                this.transportLocations = transportLocations;
                this.transportLocationComponent.items = transportLocations;
                this.transportLocationComponent.endSearch();
            });
    }

    getMoreItems(event?: {
        component: IonicSelectableComponent;
        text: string;
    }): void {
        let text = '';
        if (event) {
            text = (event.text || '').trim().toLowerCase();
        }

        // There're no more transportLocations - disable infinite scroll.
        if (this.transportLocations.length === this.amount) {
            this.transportLocationComponent.disableInfiniteScroll();
            return;
        }

        this.transportLocationEntityService
            .getWithQuery({
                start: this.transportLocations.length.toString(),
                amount: this.loadingAmount.toString(),
                search: text,
            })
            .subscribe((transportLocations) => {
                const transportL = this.transportLocations.concat(
                    transportLocations
                );
                this.transportLocations = transportL;
                this.transportLocationComponent.items = transportL;
                this.transportLocationComponent.endInfiniteScroll();
                this.page++;
            });
    }
}
