import { Component, Inject, Input } from '@angular/core';
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { Store } from '@ngrx/store';
import { Actions } from '@ngrx/effects';
import { errorOccured, ItemType, UploadRequestAction } from '@sansys/crosslib';
import { AlertController } from '@ionic/angular';
import { translate } from '@ngneat/transloco';

@Component({
    selector: 'sys-add-image-button',
    templateUrl: './add-image-button.component.html',
    styleUrls: ['./add-image-button.component.scss'],
})
export class AddImageButtonComponent {
    @Input() entity!: any;
    @Input() type!: ItemType;

    faPlusCircle = faPlusCircle;

    constructor(
        private actions$: Actions,
        private store: Store<any>,
        private alertController: AlertController
    ) {}

    async getFileFromInput(args: Event): Promise<void> {
        const files = (args.target as HTMLInputElement).files;
        if (!this.entity.id) {
            this.store.dispatch(
                errorOccured({ message: 'No entity ID found' })
            );
        }
        if (!files) {
            return;
        }
        const file = files.item(0);
        if (!file) {
            return;
        }

        const alert = await this.alertController.create({
            header: translate('Dateityp auswählen'),
            inputs: [
                {
                    name: 'fileType',
                    type: 'radio',
                    label: translate('Bild'),
                    value: 'image',
                    checked: true,
                },
                {
                    name: 'fileType',
                    type: 'radio',
                    label: translate('Protokoll'),
                    value: 'protocol',
                },
                {
                    name: 'fileType',
                    type: 'radio',
                    label: translate('Rechnung'),
                    value: 'invoice',
                },
                {
                    name: 'fileType',
                    type: 'radio',
                    label: translate('Sonstiges'),
                    value: 'misc',
                },
            ],
            buttons: [
                {
                    text: translate('Abbrechen'),
                    role: 'cancel',
                },
                {
                    text: translate('Speichern'),
                    handler: (data: string) => {
                        this.store.dispatch(
                            new UploadRequestAction({
                                file,
                                type: this.type,
                                fileType: data,
                                item: this.entity,
                            })
                        );
                    },
                },
            ],
        });

        await alert.present();
    }
}
