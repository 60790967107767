import { NgModule } from '@angular/core';
import { StationPickerComponent } from './station-picker.component';
import { IonicSelectableModule } from 'ionic-selectable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
    declarations: [StationPickerComponent],
    imports: [
        IonicSelectableModule,
        FormsModule,
        IonicModule,
        ReactiveFormsModule,
        CommonModule,
        TranslocoModule,
    ],
    exports: [StationPickerComponent],
})
export class StationPickerModule {}
