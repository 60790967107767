import { Component } from '@angular/core';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { ModalController } from '@ionic/angular';
import { LocalStorageService } from '@sansys/crosslib';

@Component({
    selector: 'sys-android-geo-modal',
    templateUrl: './android-geo-modal.component.html',
    styleUrls: ['./android-geo-modal.component.scss'],
})
export class AndroidGeoModalComponent {
    faTimes = faTimes;

    constructor(
        private modalController: ModalController,
        private localStorageService: LocalStorageService
    ) {}

    closeModal(): void {
        this.modalController.dismiss();
    }

    accept(): void {
        this.localStorageService.setItem('androidAllowGeoLocation', true);
        this.closeModal();
    }
}
