import { Component, Input } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import {
    faTimes,
    faSave,
    faPlusCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { EmployeeEntityService, ItemType } from '@sansys/crosslib';
import { translate } from '@ngneat/transloco';

@Component({
    selector: 'sys-select-location[type]',
    templateUrl: './select-location.component.html',
    styleUrls: ['./select-location.component.scss'],
})
export class SelectLocationComponent {
    @Input() type!: ItemType;
    @Input() locationType: 'home' | 'owner' | 'location' = 'owner';
    @Input() updateMode = true;
    @Input() selectedLocation = '';

    faTimes = faTimes;
    faSave = faSave;
    faPlusCircle = faPlusCircle;
    availableLocations: string[] = [];

    constructor(
        private modalController: ModalController,
        private alertController: AlertController,
        private employeeEntityService: EmployeeEntityService
    ) {}

    ionViewWillEnter(): void {
        this.employeeEntityService.entities$.subscribe((employees) => {
            employees.forEach((employee) => {
                this.availableLocations.push(
                    employee.firstName + ' ' + employee.lastName
                );
            });
        });
        // this.companyService
        //     .getLocationCollection()
        //     .pipe(map((locations) => locations.sort()))
        //     .subscribe((locationCollection) => {
        //         this.availableLocations = locationCollection;
        //     });
    }

    closeModal(): void {
        this.modalController.dismiss();
    }

    updateInputValue(event: string): void {
        this.selectedLocation = event;
        this.confirmLocation();
    }

    confirmLocation(): void {
        setTimeout(() => {
            this.modalController.dismiss({
                selectedLocation: this.selectedLocation,
            });
        });
    }

    private updateLocationCollection(newEntry: string): void {
        // this.selectedLocation = newEntry;
        // if (!this.availableLocations.includes(newEntry)) {
        //     this.availableLocations.push(newEntry);
        //     this.companyService
        //         .putLocationCollection(this.availableLocations)
        //         .subscribe(() => {
        //             this.availableLocations.sort();
        //         });
        // }
    }

    async addNewLocationCollectionItem(): Promise<void> {
        const alert = await this.alertController.create({
            header: translate('Neue Vorlage'),
            inputs: [
                {
                    name: 'name',
                    type: 'text',
                    placeholder: translate('Name'),
                },
            ],
            buttons: [
                {
                    text: translate('Abbrechen'),
                    role: 'cancel',
                },
                {
                    text: translate('Anlegen'),
                    handler: (data: { name: string }) => {
                        this.updateLocationCollection(data.name);
                    },
                },
            ],
        });

        await alert.present();
    }
}
