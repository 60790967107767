import { Inject, Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { combineLatest, Observable } from 'rxjs';
import {
    API_BASE_FILE_SERVER,
    currentUser,
    errorOccured,
    ItemType,
    KeyyEntityService,
    MaterialEntityService,
    ScannerType,
    showKeyyDetailsPage,
    showKeyyQuickFunctions,
    showMaterialDetailsPage,
    showMaterialQuickFunctions,
    showDeviceDetailPages,
    showDeviceQuickFunctions,
    DeviceEntityService,
    setDeviceNotWorking,
} from '@sansys/crosslib';
import { Store } from '@ngrx/store';
import { AlertController } from '@ionic/angular';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { AlertInput } from '@ionic/core/dist/types/components/alert/alert-interface';
import { translate } from '@ngneat/transloco';
import { ScanResultItem } from './scan-result-item.type';
import { MaterialService } from '../../features/tabs/warehouse/material/material.service';
import { scannerResultAvailable } from '../scanner.actions';

@Injectable({ providedIn: 'root' })
export class ScannerService {
    constructor(
        private http: HttpClient,
        private store: Store<any>,
        private alertController: AlertController,
        private barcodeScanner: BarcodeScanner,
        private injector: Injector,
        private materialService: MaterialService,
        @Inject(API_BASE_FILE_SERVER) private environment: { server: string }
    ) {}

    public scanCode(scanRequestSource: ScannerType, itemType?: ItemType): void {
        this.barcodeScanner
            .scan({
                showTorchButton: true,
            })
            .then((barcodeData) => {
                this.store.dispatch(
                    scannerResultAvailable({
                        scannedCode: barcodeData.text,
                        scanRequestSource,
                        itemType,
                    })
                );
            })
            .catch(async () => {
                const alert = await this.alertController.create({
                    header: translate('Wert eingeben'),
                    inputs: [
                        {
                            name: 'value',
                            type: 'text',
                            placeholder: translate('Wert'),
                        },
                    ],
                    buttons: [
                        {
                            text: translate('Abbrechen'),
                            role: 'cancel',
                        },
                        {
                            text: translate('Ok'),
                            handler: (data: { value: string }) => {
                                this.store.dispatch(
                                    scannerResultAvailable({
                                        scannedCode: data.value,
                                        scanRequestSource,
                                        itemType,
                                    })
                                );
                            },
                        },
                    ],
                });

                await alert.present();
            });
    }

    getEntities(
        code: string,
        itemType?: ItemType
    ): Observable<ScanResultItem[]> {
        return this.http.post<ScanResultItem[]>(
            `${this.environment.server}/general/getentities`,
            { code, itemType }
        );
    }

    private showQuickFunctions(
        resultItem: ScanResultItem,
        onlyBook?: boolean
    ): void {
        switch (resultItem.type) {
            case 'material':
                this.injector
                    .get(MaterialEntityService)
                    .getByKey(resultItem.id)
                    .subscribe((material) => {
                        this.store.dispatch(
                            showMaterialQuickFunctions({
                                material,
                                fullScreen: true,
                                onlyBook,
                            })
                        );
                    });
                break;
            case 'device':
                this.injector
                    .get(DeviceEntityService)
                    .getByKey(resultItem.id)
                    .subscribe((device) =>
                        this.store.dispatch(
                            showDeviceQuickFunctions({
                                device,
                                fullScreen: true,
                            })
                        )
                    );
                break;
            case 'keyy':
                this.injector
                    .get(KeyyEntityService)
                    .getByKey(resultItem.id)
                    .subscribe((keyy) =>
                        this.store.dispatch(
                            showKeyyQuickFunctions({
                                keyy,
                                fullScreen: true,
                            })
                        )
                    );
                break;
        }
    }

    private async showResultAlert(
        scanResult: ScanResultItem[],
        code: string,
        scanRequestSource: ScannerType
    ): Promise<void> {
        const inputs: AlertInput[] = [];
        scanResult.forEach((resultItem) => {
            inputs.push({
                name: translate(resultItem.type),
                value: resultItem,
                label: resultItem.name,
                type: 'radio',
            });
        });
        const alert = await this.alertController.create({
            header: translate('Ergebnis wählen'),
            subHeader: translate('Code: ') + code,
            inputs: inputs,
            buttons: [
                {
                    text: translate('Zurück'),
                    role: 'cancel',
                    cssClass: 'secondary',
                },
                {
                    text: translate('Ok'),
                    handler: (data) => {
                        this.switchToTypeAndHandleAction(
                            data,
                            scanRequestSource
                        );
                    },
                },
            ],
        });

        await alert.present();
    }

    private switchToTypeAndHandleAction(
        selectedResult: ScanResultItem,
        scanRequestSource: ScannerType
    ): void {
        switch (selectedResult.type) {
            case 'material':
                this.processMaterialActions(scanRequestSource, selectedResult);
                break;
            case 'device':
                this.processDeviceActions(scanRequestSource, selectedResult);
                break;
            case 'keyy':
                this.processKeyyActions(scanRequestSource, selectedResult);
                break;
        }
    }

    processScanResult(
        scanResult: ScanResultItem[],
        code: string,
        scanRequestSource: ScannerType
    ): void {
        if (scanResult.length === 1) {
            this.switchToTypeAndHandleAction(scanResult[0], scanRequestSource);
        } else {
            this.showResultAlert(scanResult, code, scanRequestSource);
        }
    }

    private checkIfResultsAvailable(
        scanResult: ScanResultItem[],
        code: string
    ): boolean {
        if (scanResult.length === 0) {
            this.store.dispatch(
                errorOccured({
                    message: `${translate(
                        'Es wurde kein Eintrag gefunden zum Code'
                    )} "${code}"`,
                    title: 'Ergebnis',
                })
            );
            return false;
        }
        return true;
    }

    private processMaterialActions(
        scanRequestSource: ScannerType,
        resultItem: ScanResultItem
    ): void {
        switch (scanRequestSource) {
            case 'quickfunctions':
                return this.showQuickFunctions(resultItem);
            case 'details':
                this.store.dispatch(
                    showMaterialDetailsPage({
                        materialId: resultItem.id,
                    })
                );
                break;
            case 'book':
                this.showQuickFunctions(resultItem, true);
                break;
        }
    }

    private processDeviceActions(
        scanRequestSource: ScannerType,
        resultItem: ScanResultItem
    ): void {
        switch (scanRequestSource) {
            case 'quickfunctions':
                return this.showQuickFunctions(resultItem);
            case 'details':
                this.store.dispatch(
                    showDeviceDetailPages({
                        deviceId: resultItem.id,
                    })
                );
                break;
            case 'book':
                this.showQuickFunctions(resultItem, true);
                break;
            case 'notWorking':
                combineLatest([
                    this.injector
                        .get(DeviceEntityService)
                        .getByKey(resultItem.id),
                    this.store.select(currentUser),
                ]).subscribe(([device, currentU]) => {
                    if (currentU) {
                        this.store.dispatch(
                            setDeviceNotWorking({
                                device,
                                notWorking: true,
                                currentUserFullName:
                                    currentU.user.firstName +
                                    ' ' +
                                    currentU.user.lastName,
                            })
                        );
                    }
                });
                break;
        }
    }

    private processKeyyActions(
        scanRequestSource: ScannerType,
        resultItem: ScanResultItem
    ): void {
        switch (scanRequestSource) {
            case 'quickfunctions':
                return this.showQuickFunctions(resultItem);
            case 'details':
                this.store.dispatch(
                    showKeyyDetailsPage({
                        keyyId: resultItem.id,
                    })
                );
                break;
            case 'book':
                this.showQuickFunctions(resultItem, true);
                break;
        }
    }

    processActionScannerResult(
        scannedCode: string,
        scanRequestSource: ScannerType,
        itemType?: ItemType
    ): void {
        if (
            ![
                'book',
                'refill',
                'toOrder',
                'details',
                'quickfunctions',
                'notWorking',
            ].includes(scanRequestSource)
        ) {
            return;
        }
        this.getEntities(scannedCode, itemType).subscribe((result) => {
            if (!this.checkIfResultsAvailable(result, scannedCode)) {
                return;
            }

            this.processScanResult(result, scannedCode, scanRequestSource);
        });
    }
}
