import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { IonicModule } from '@ionic/angular';
import { IonicDateTypeComponent } from './ionic-date-type.component';
import { IonicHealthInsuranceSelectComponent } from './ionic-health-insurance-select.component';
import { IonicSectionWrapperComponent } from './ionic-section.wrapper';
import { LocationInputTypeComponent } from './location-input-type.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormlyGroupWrapperComponent } from './formly-group-wrapper.component';
import { InputWithScannerTypeComponent } from './input-with-scanner-type.component';
import { CustomCommonModule } from '../custom-common.module';
import { IonicTextareaTypeComponent } from './ionic-textarea-type.component';
import { InsurancePickerModule } from '../insurance-picker/insurance-picker.module';
import { TextTypeComponent } from './text-type.component';
import { TranslocoModule } from '@ngneat/transloco';
import { InputWithLinkTypeComponent } from './input-with-link-type.component';

@NgModule({
    declarations: [
        IonicDateTypeComponent,
        IonicHealthInsuranceSelectComponent,
        IonicSectionWrapperComponent,
        LocationInputTypeComponent,
        FormlyGroupWrapperComponent,
        InputWithLinkTypeComponent,
        InputWithScannerTypeComponent,
        IonicTextareaTypeComponent,
        TextTypeComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormlyModule,
        IonicModule,
        FontAwesomeModule,
        CustomCommonModule,
        InsurancePickerModule,
        TranslocoModule,
    ],
})
export class CustomFormlyModule {}
