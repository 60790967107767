import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { getScannerResult } from './scanner.actions';
import { ScannerService } from './scanner/scanner.service';

@Injectable({ providedIn: 'root' })
export class ScannerEffects {
    constructor(
        private actions$: Actions,
        private scannerService: ScannerService
    ) {}

    scannerRequest$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(getScannerResult),
                tap((data) => {
                    this.scannerService.scanCode(data.scanRequestSource);
                })
            ),
        { dispatch: false }
    );
}
