import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
    selector: 'sys-ionic-section-wrapper-panel',
    template: `
        <ion-list-header>
            {{ to.label }}
        </ion-list-header>
        <ng-container #fieldComponent></ng-container>
    `,
})
export class IonicSectionWrapperComponent extends FieldWrapper {}
