import { Component, Input, OnInit } from '@angular/core';
import { AlertButton } from '@ionic/core/dist/types/components/alert/alert-interface';
import { TextFieldTypes } from '@ionic/core/dist/types/interface';
import { ModalController } from '@ionic/angular';
import { faTimes, faSave } from '@fortawesome/pro-regular-svg-icons';
import { Store } from '@ngrx/store';
import { errorOccured } from '@sansys/crosslib';
import { translate } from '@ngneat/transloco';
import { SysAlertInput } from './alert-input.model';

@Component({
    selector: 'sys-input-alert',
    templateUrl: './input-alert.component.html',
    styleUrls: ['./input-alert.component.scss'],
})
export class InputAlertComponent implements OnInit {
    @Input() title?: string;
    @Input() subTitle?: string;
    @Input() buttons?: AlertButton[];
    @Input() saveButton?: AlertButton;
    @Input() inputs!: SysAlertInput[];
    @Input() mandatoryFields = [];

    faTimes = faTimes;
    faSave = faSave;

    model: any = {};

    constructor(
        private modalController: ModalController,
        private store: Store<any>
    ) {}

    ngOnInit(): void {
        this.inputs.forEach((input) => {
            if (!input.name) {
                return;
            }
            this.model[input.name] = input.value;
        });
    }

    closeModal(): void {
        this.modalController.dismiss();
    }

    handleClick(handler: any): void {
        if (handler) {
            handler(this.model);
        }
        this.closeModal();
    }

    save(): void {
        let error = false;
        this.mandatoryFields?.forEach((field) => {
            if (error) {
                return;
            }
            if (!this.model[field]) {
                this.store.dispatch(
                    errorOccured({
                        message: `Please enter ${translate(field)}.`,
                    })
                );
                error = true;
            }
        });
        if (error || !this.saveButton?.handler) {
            return;
        }
        this.saveButton.handler(this.model);
        this.closeModal();
    }

    getButtonColor(button: AlertButton): string {
        if (!button.cssClass) {
            return '';
        }
        if (typeof button.cssClass === 'object') {
            return button.cssClass[0];
        }
        return button.cssClass;
    }

    getMin(input: SysAlertInput): string | undefined {
        if (typeof input.min === 'number') {
            return input.min.toString();
        }
        return input.min;
    }

    getMax(input: SysAlertInput): string | undefined {
        if (typeof input.max === 'number') {
            return input.max.toString();
        }
        return input.max;
    }

    getInputType(input: SysAlertInput): TextFieldTypes {
        if (!input.type) {
            return 'text';
        }
        return input.type as TextFieldTypes;
    }

    missingRequiredField(): boolean {
        let missing = false;
        this.inputs.forEach((input) => {
            if (input.required && input.name && !this.model[input.name]) {
                missing = true;
            }
        });
        return missing;
    }
}
