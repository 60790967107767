import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'sys-ionic-date-picker',
    template: `
        <ion-item>
            <ion-label position="stacked">{{ to.label }}</ion-label>
            <ion-datetime
                displayFormat="DD.MM.YYYY"
                [placeholder]="to.placeholder"
                [formControl]="formControl"
                cancelText="{{ 'Abbrechen' | transloco }}"
                doneText="{{ 'Ok' | transloco }}"
            ></ion-datetime>
        </ion-item>
    `,
})
export class IonicDateTypeComponent extends FieldType {
    public formControl!: FormControl;
}
