<ionic-selectable
    #transportLocationComponent
    [(ngModel)]="currentTransportLocation"
    itemValueField="id"
    itemTextField="companyName"
    [(items)]="transportLocations"
    [canSearch]="true"
    [canClear]="true"
    [canAddItem]="canAdd"
    [canSaveItem]="canAdd || canEdit"
    addButtonText="{{ 'Neu' | transloco }}"
    clearButtonText="{{ 'Leeren' | transloco }}"
    closeButtonText="{{ 'Zurück' | transloco }}"
    searchPlaceholder="{{ 'Suche' | transloco }}"
    [hasInfiniteScroll]="true"
    [placeholder]="placeholder"
    (onAddItem)="onAddTransportLocation($event)"
    (onSaveItem)="onEditTransportLocation($event)"
    (onSearch)="searchItems($event)"
    (onInfiniteScroll)="getMoreItems($event)"
    (onClose)="setTransportLocation()"
    searchFailText="{{ 'Kein Eintrag gefunden' | transloco }}"
>
    <ng-template ionicSelectableItemTemplate let-transportLocation="item">
        {{ transportLocation.companyName }}
    </ng-template>
    <ng-template
        ionicSelectableAddItemTemplate
        let-transportLocation="item"
        let-isAdd="isAdd"
    >
        <ion-toolbar class="pt-32">
            <ion-title
                >{{ 'Start - / Zielort' | transloco }}
                {{
                    isAdd
                        ? ('erstellen' | transloco)
                        : ('bearbeiten' | transloco)
                }}</ion-title
            >
        </ion-toolbar>

        <form [formGroup]="transportLocationForm" novalidate>
            <ion-list>
                <ion-item>
                    <ion-label position="stacked"
                        >{{ 'Name' | transloco }} *</ion-label
                    >
                    <ion-input
                        type="text"
                        formControlName="companyName"
                        autocorrect="off"
                        autocapitalize="sentences"
                    >
                    </ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="stacked"
                        >{{ 'Strasse' | transloco }} *</ion-label
                    >
                    <ion-input
                        type="text"
                        formControlName="street"
                        autocorrect="off"
                        autocapitalize="sentences"
                    >
                    </ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="stacked"
                        >{{ 'PLZ' | transloco }} *</ion-label
                    >
                    <ion-input
                        type="text"
                        formControlName="zip"
                        autocorrect="off"
                        autocapitalize="none"
                    >
                    </ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="stacked"
                        >{{ 'Ort' | transloco }} *</ion-label
                    >
                    <ion-input
                        type="text"
                        formControlName="city"
                        autocorrect="off"
                        autocapitalize="sentences"
                    >
                    </ion-input>
                </ion-item>
                <ion-item [hidden]="!isAdd">
                    <ion-label>{{
                        'Nur für diesen Transport anlegen' | transloco
                    }}</ion-label>
                    <ion-toggle formControlName="isManual"></ion-toggle>
                </ion-item>
            </ion-list>
        </form>
        <ion-footer>
            <ion-toolbar>
                <ion-row>
                    <ion-col>
                        <ion-button
                            expand="full"
                            (click)="
                                transportLocationComponent.hideAddItemTemplate()
                            "
                        >
                            {{ 'Abbrechen' | transloco }}
                        </ion-button>
                    </ion-col>
                    <ion-col>
                        <ion-button
                            expand="full"
                            (click)="
                                isAdd
                                    ? addTransportLocation()
                                    : saveTransportLocation(transportLocation)
                            "
                            [disabled]="!transportLocationForm.valid"
                        >
                            {{
                                isAdd
                                    ? ('Erstellen' | transloco)
                                    : ('Speichern' | transloco)
                            }}
                        </ion-button>
                    </ion-col>
                </ion-row>
            </ion-toolbar>
        </ion-footer>
    </ng-template>
</ionic-selectable>
