import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { FormControl } from '@angular/forms';
import { faPhone } from '@fortawesome/pro-regular-svg-icons';

@Component({
    selector: 'sys-input-with-link',
    template: `
        <div class="sys--input-container">
            <ion-item class="sys--flex-grow">
                <ion-label position="stacked">{{ to.label }}</ion-label>
                <ion-input
                    [formControl]="getFormControl()"
                    [formlyAttributes]="field"
                    [placeholder]="to.placeholder"
                ></ion-input>
            </ion-item>
            <ion-button
                [href]="'tel:' + formControl.value"
                slot="end"
                color="primary"
                [disabled]="!formControl.value"
                class="button-within-input h-60"
            >
                <fa-icon [icon]="faPhone" size="lg"></fa-icon>
            </ion-button>
        </div>
    `,
})
export class InputWithLinkTypeComponent extends FieldType {
    faPhone = faPhone;

    getFormControl(): FormControl {
        return this.formControl as FormControl;
    }
}
