import { Injectable } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import {
    AuthService,
    errorOccured,
    ErrorType,
    setCurrentWorktime,
    TransportLocation,
    transportLocationCreated,
    TrelloService,
} from '@sansys/crosslib';
import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AlertButton } from '@ionic/core/dist/types/components/alert/alert-interface';
import { BugReportComponent } from '../../features/tabs/info/bug-report/bug-report.component';
import { translate } from '@ngneat/transloco';

@Injectable({ providedIn: 'root' })
export class ErrorMessageService {
    constructor(
        private alertController: AlertController,
        private http: HttpClient,
        private trelloService: TrelloService,
        private modalController: ModalController,
        private authService: AuthService,
        private store: Store<any>
    ) {}

    private currentErrorAlert?: HTMLIonAlertElement;
    private alertIsActive = false;

    async displayErrorMessage(error: ErrorType): Promise<void> {
        if (this.alertIsActive) {
            return;
        }

        this.alertIsActive = true;

        setTimeout(() => {
            this.alertIsActive = false;
        }, 500);

        if (this.currentErrorAlert) {
            await this.currentErrorAlert.dismiss();
        }

        const message =
            error.message === 'Unknown Error'
                ? '<h4>' +
                  translate('Der Server nicht erreichbar.') +
                  '</h4>' +
                  translate(
                      'Bitte überprüfen Sie Ihre Internetverbindung oder wenden Sie sich an '
                  ) +
                  '<a href="mailto:support@sansys.ch">support@sansys.ch</a>'
                : error.message;

        let header = 'Fehler';
        if (error.title) {
            header = error.title;
        }

        let buttons: (string | AlertButton)[] = [translate('OK')];

        if (header === 'Fehler') {
            buttons = [
                {
                    text: translate('An SANSYS übertragen'),
                    handler: async () => {
                        const modal = await this.modalController.create({
                            component: BugReportComponent,
                            componentProps: { message },
                        });
                        return await modal.present();
                    },
                },
                translate('OK'),
            ];
        }

        this.currentErrorAlert = await this.alertController.create({
            header: translate(header),
            message: translate(message),
            buttons: buttons,
        });

        await this.currentErrorAlert.present();
    }

    public async askForConfirmation(
        request: HttpRequest<any>,
        options: string
    ): Promise<void> {
        if (this.currentErrorAlert) {
            await this.currentErrorAlert.dismiss();
        }

        const alert = await this.alertController.create({
            header: translate('Mögliche Duplikate gefunden'),
            subHeader: options,
            message: translate('Trotzdem speichern?'),
            buttons: [
                {
                    text: translate('Abbrechen'),
                    role: 'cancel',
                },
                {
                    text: translate('Anlegen'),
                    handler: () => {
                        request = request.clone({
                            body: {
                                ...request.body,
                                ignoreExistingTransportLocations: true,
                            },
                        });
                        this.http.request(request).subscribe((response) => {
                            const transportLocation = ((response as unknown) as HttpResponse<TransportLocation>)
                                .body;
                            if (transportLocation) {
                                this.store.dispatch(
                                    transportLocationCreated({
                                        transportLocation,
                                    })
                                );
                            }
                        });
                    },
                },
            ],
        });

        await alert.present();
    }

    public async askForWorktimeReduction(
        request: HttpRequest<any>,
        message: string
    ): Promise<void> {
        if (this.currentErrorAlert) {
            await this.currentErrorAlert.dismiss();
        }

        const alert = await this.alertController.create({
            header: message,
            subHeader: translate(
                'Ursprüngliche Arbeitszeit stornieren und neue erstellen?'
            ),
            buttons: [
                {
                    text: translate('Abbrechen'),
                    role: 'cancel',
                },
                {
                    text: translate('Neu erstellen'),
                    handler: () => {
                        request = request.clone({
                            body: {
                                ...request.body,
                                reduceExistingWorktime: true,
                            },
                        });
                        this.http.request(request).subscribe(async () => {
                            this.authService
                                .getCurrentWorkTime()
                                .subscribe((currentW) => {
                                    this.store.dispatch(
                                        setCurrentWorktime({
                                            currentWorktime: currentW,
                                        })
                                    );
                                });
                            this.store.dispatch(
                                errorOccured({
                                    title: 'Info',
                                    message: 'Arbeitszeit erstellt.',
                                })
                            );
                        });
                    },
                },
            ],
        });

        await alert.present();
    }
}
