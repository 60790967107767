import { NgModule } from '@angular/core';
import { TransportLocationPickerComponent } from './transport-location-picker.component';
import { IonicSelectableModule } from 'ionic-selectable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
    declarations: [TransportLocationPickerComponent],
    imports: [
        IonicSelectableModule,
        FormsModule,
        IonicModule,
        ReactiveFormsModule,
        CommonModule,
        TranslocoModule,
    ],
    exports: [TransportLocationPickerComponent],
})
export class TransportLocationPickerModule {}
