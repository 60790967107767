<ion-toolbar>
    <ion-buttons slot="secondary">
        <ion-button (click)="closeModal()">
            <fa-icon [icon]="faTimes" size="lg"></fa-icon>
        </ion-button>
    </ion-buttons>
    <ion-title *ngIf="entity.itemNumber"
        >{{ entity.itemNumber }}: {{ entity.name }}</ion-title
    >
    <ion-title *ngIf="!entity.itemNumber">{{ type | transloco }}</ion-title>
    <ion-buttons slot="primary" *ngIf="allowItemUpdates">
        <ion-button (click)="emitOpenDetails()">
            <fa-icon [icon]="faInfoCircle" size="lg"></fa-icon>
        </ion-button>
    </ion-buttons>
</ion-toolbar>

<ion-content class="ion-padding ion-text-center">
    <ng-content select="[main-edit-area]"></ng-content>
</ion-content>
