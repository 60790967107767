import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'sys-ionic-textarea',
    template: `
        <ion-item>
            <ion-label position="stacked">{{ to.label }}</ion-label>
            <p>{{ value }}</p>
        </ion-item>
    `,
})
export class TextTypeComponent extends FieldType {
    public formControl!: FormControl;

    isDisabled(): boolean {
        return true;
    }
}
