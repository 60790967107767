<ion-header>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-button (click)="closeModal()">
                {{ 'Abbrechen' | transloco }}
            </ion-button>
        </ion-buttons>
        <ion-title>{{ 'Fehler senden' | transloco }}</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="sendReport()">
                {{ 'Senden' | transloco }}
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
    <ion-list lines="full" class="ion-no-margin">
        <ion-item>
            <sys-image-uploader
                class="w-100-p"
                [type]="'bugReport'"
                [fileType]="'bugReport'"
                [entity]="bugReport"
                [buttonText]="'Bild hinzufügen' | transloco"
                (imageUpload)="fileAdded($event)"
            ></sys-image-uploader>
        </ion-item>
        <ion-item>
            <ion-label position="stacked">{{
                'Bereich' | transloco
            }}</ion-label>
            <ion-input
                placeholder="{{ 'Bereich' | transloco }}"
                [(ngModel)]="bugReport.topic"
                type="text"
            ></ion-input>
        </ion-item>

        <ion-item lines="none">
            <ion-label position="stacked">{{
                'Details' | transloco
            }}</ion-label>
            <ion-textarea
                [rows]="10"
                [placeholder]="
                    'Wie kann der Fehler reproduziert werden?' | transloco
                "
                [(ngModel)]="bugReport.details"
            ></ion-textarea>
        </ion-item>
    </ion-list>
</ion-content>
