<ionic-selectable
    #insuranceComponent
    [(ngModel)]="currentInsurance"
    itemValueField="id"
    itemTextField="name"
    [(items)]="insurances"
    [canSearch]="true"
    [canClear]="true"
    addButtonText="{{ 'Neu' | transloco }}"
    clearButtonText="{{ 'Leeren' | transloco }}"
    closeButtonText="{{ 'Zurück' | transloco }}"
    searchPlaceholder="{{ 'Suche' | transloco }}"
    [placeholder]="placeholder"
    (onAddItem)="onAddInsurance($event)"
    (onSaveItem)="onEditInsurance($event)"
    (onClose)="setInsurance()"
    (onOpen)="reloadData()"
    searchFailText="{{ 'Kein Eintrag gefunden' | transloco }}"
    [disabled]="!('passengers|EDIT' | permission | async)"
>
    <ng-template ionicSelectableItemTemplate let-insurance="item">
        {{ insurance.name }}
    </ng-template>
    <ng-template
        ionicSelectableAddItemTemplate
        let-insurance="item"
        let-isAdd="isAdd"
    >
        <ion-toolbar class="pt-32">
            <ion-title
                >{{ 'Versicherung' | transloco }}
                {{
                    isAdd
                        ? ('erstellen' | transloco)
                        : ('bearbeiten' | transloco)
                }}</ion-title
            >
        </ion-toolbar>
        <form [formGroup]="insuranceForm" novalidate>
            <ion-list>
                <ion-item>
                    <ion-label position="stacked">{{
                        'Name' | transloco
                    }}</ion-label>
                    <ion-input
                        type="text"
                        formControlName="name"
                        autocorrect="off"
                        autocapitalize="sentences"
                    >
                    </ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="stacked">{{
                        'Strasse' | transloco
                    }}</ion-label>
                    <ion-input
                        type="text"
                        formControlName="street"
                        autocorrect="off"
                        autocapitalize="sentences"
                    >
                    </ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="stacked">{{
                        'PLZ' | transloco
                    }}</ion-label>
                    <ion-input
                        type="text"
                        formControlName="zip"
                        autocorrect="off"
                        autocapitalize="none"
                    >
                    </ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="stacked">{{
                        'Ort' | transloco
                    }}</ion-label>
                    <ion-input
                        type="text"
                        formControlName="city"
                        autocorrect="off"
                        autocapitalize="sentences"
                    >
                    </ion-input>
                </ion-item>
            </ion-list>
        </form>
        <ion-footer>
            <ion-toolbar>
                <ion-row>
                    <ion-col>
                        <ion-button
                            expand="full"
                            (click)="insuranceComponent.hideAddItemTemplate()"
                        >
                            {{ 'Abbrechen' | transloco }}
                        </ion-button>
                    </ion-col>
                    <ion-col>
                        <ion-button
                            expand="full"
                            (click)="
                                isAdd
                                    ? addInsurance()
                                    : saveInsurance(insurance)
                            "
                            [disabled]="!insuranceForm.valid"
                        >
                            {{
                                isAdd
                                    ? ('Erstellen' | transloco)
                                    : ('Speichern' | transloco)
                            }}
                        </ion-button>
                    </ion-col>
                </ion-row>
            </ion-toolbar>
        </ion-footer>
    </ng-template>
</ionic-selectable>
