<ng-container>
    <label for="imageInputAdd" class="ionic-button-small list-button--primary">
        <fa-icon [icon]="faPlusCircle" size="lg"></fa-icon>
    </label>
    <input
        accept=".jpg,.jpeg,.png"
        type="file"
        id="imageInputAdd"
        class="display-none"
        (change)="getFileFromInput($event)"
    />
</ng-container>
