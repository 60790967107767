import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { Store } from '@ngrx/store';
import {
    CompanyService,
    CurrentUser,
    LocalStorageService,
    userLogin,
    ListOptionsType,
    listOptionsDefaultValue,
    ListOptionsActions,
} from '@sansys/crosslib';
import { Router } from '@angular/router';
import { setCulture } from '@syncfusion/ej2-base';
import { TranslocoService } from '@ngneat/transloco';

@Component({
    selector: 'sys-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {
    title = 'employee';

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private store: Store<any>,
        private router: Router,
        private translocoServce: TranslocoService,
        private localStorageService: LocalStorageService,
        private companyService: CompanyService
    ) {
        this.platform.ready().then(() => {
            this.initializeApp();
        });
    }

    initializeApp(): void {
        this.statusBar.styleDefault();
        this.splashScreen.hide();
        const currentUser:
            | CurrentUser
            | undefined = this.localStorageService.getItem('sansys_user');
        const savedColor: string | undefined = this.localStorageService.getItem(
            'sansys_color'
        );
        if (savedColor) {
            this.companyService.setColors(savedColor);
        }
        if (currentUser) {
            this.store.dispatch(userLogin({ currentUser }));
            if (!document.documentURI.includes('tabs')) {
                this.router.navigate(['tabs', 'start']);
            }
        }

        let listOptions:
            | ListOptionsType
            | null
            | undefined = this.localStorageService.getItem('listOptions');
        if (!listOptions) {
            listOptions = listOptionsDefaultValue;
        }

        if (listOptions) {
            this.store.dispatch(
                ListOptionsActions.setListOptions({
                    listOptions,
                })
            );
        }

        const language = this.localStorageService.getItem('employee-language');
        if (language === 'fr') {
            this.translocoServce.setActiveLang('medical_fr-PF');
            setCulture('fr-PF');
        } else {
            this.translocoServce.setActiveLang('medical_de-CH');
            setCulture('de-CH');
        }
    }
}
