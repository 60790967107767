<ion-header>
    <ion-toolbar color="tool">
        <ion-buttons slot="secondary">
            <ion-button (click)="closeModal()">
                <fa-icon [icon]="faTimes" size="lg"></fa-icon>
            </ion-button>
        </ion-buttons>
        <ion-title class="pl-40 pr-40">
            {{ 'Standortdaten' | transloco }}
        </ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-content__height-fix ion-padding">
    <p>
        <!-- prettier-ignore -->
        {{'SANSYS Mitarbeiter sammelt Standortdaten, um den aktuellen Ort des Fahrzeugs zu ermitteln, auch wenn die App geschlossen oder nicht in Verwendung ist.' | transloco}}
    </p>

    <p>
        English Version: SANSYS Mitarbeiter collects location data to determine
        the current vehicle location even when the app is closed or not in use.
    </p>

    <ion-button (click)="accept()" color="success" expand="block" class="mb-20">
        {{ 'Ok' | transloco }}
    </ion-button>

    <ion-button (click)="closeModal()" color="dark" expand="block">
        {{ 'Abbrechen' | transloco }}
    </ion-button>
</ion-content>
