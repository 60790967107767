import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'sys-input-with-scanner',
    template: `
        <ion-item>
            <ion-label position="stacked">{{ to.label }}</ion-label>
            <ion-input
                [formControl]="getFormControl()"
                [formlyAttributes]="field"
                [placeholder]="to.placeholder"
            ></ion-input>
            <sys-scanner-button
                slot="end"
                class="button-within-input"
                [scanRequestSource]="to.scannerType"
                (scannedCode)="updateInputValue($event)"
            ></sys-scanner-button>
        </ion-item>
    `,
})
export class InputWithScannerTypeComponent extends FieldType {
    getFormControl(): FormControl {
        return this.formControl as FormControl;
    }

    updateInputValue(args: any): void {
        if (this.to.scannerType === args.type) {
            this.formControl.setValue(args.value);
        }
    }
}
