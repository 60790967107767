import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ItemType } from '@sansys/crosslib';
import {
    faInfoCircle,
    faTimes,
    faShapes,
} from '@fortawesome/pro-regular-svg-icons';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'sys-quick-functions-wrapper[type]',
    templateUrl: './quick-functions-wrapper.component.html',
    styleUrls: ['./quick-functions-wrapper.component.scss'],
})
export class QuickFunctionsWrapperComponent {
    @Input() type!: ItemType;
    @Input() entity?: any;
    @Output() openDetails = new EventEmitter<void>();
    @Input() allowItemUpdates = true;

    faTimes = faTimes;
    faInfoCircle = faInfoCircle;
    faShapes = faShapes;

    constructor(private modalCtrl: ModalController) {}

    async closeModal(): Promise<void> {
        await this.modalCtrl.dismiss();
    }

    emitOpenDetails(): void {
        this.openDetails.emit();
        this.closeModal();
    }
}
