<ion-button
    expand="full"
    color="dark"
    (click)="showSelectImageNotice()"
    *ngIf="isMobile"
>
    <fa-icon
        class="ion-margin-end"
        slot="start"
        [icon]="faCamera"
        size="lg"
    ></fa-icon>
    {{ buttonText | transloco }}
</ion-button>

<img [src]="fileLink" alt="" />

<ng-container *ngIf="!isMobile">
    <div class="center--vertically mb-12">
        <label for="imageInput" class="ionic-button">
            <fa-icon
                class="mr-8"
                slot="start"
                [icon]="faCamera"
                size="lg"
            ></fa-icon>
            {{
                buttonText
                    ? (buttonText | transloco)
                    : ('Bild ändern' | transloco)
            }}
        </label>
        <input
            accept=".jpg,.jpeg,.png"
            type="file"
            id="imageInput"
            class="display-none"
            (change)="getFileFromInput($event)"
        />
    </div>
</ng-container>

<div class="message" *ngIf="isInProgress$ | async">
    <div style="margin-bottom: 14px">Uploading... {{ progress$ | async }}%</div>
</div>

<div class="message" *ngIf="isInProgress$ | async">
    <button (click)="cancelUpload()">Cancel Upload</button>
</div>

<div class="message danger" *ngIf="hasFailed$ | async" (click)="resetUpload()">
    Error: {{ error$ | async }}
</div>
