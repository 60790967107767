import { Injectable, Injector, NgZone } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    VehicleEntityService,
    setCurrentWorktime,
    AuthService,
    TransportEntityService,
    employeeAddedToVehicle,
    GeoLocationModel,
    GeoLocationEntityService,
    Vehicle,
    Employee,
    logout,
} from '@sansys/crosslib';
import { tap } from 'rxjs/operators';
import { removeEmployeeFromVehicle } from './start.actions';
import { Store } from '@ngrx/store';
import { NavController } from '@ionic/angular';
import BackgroundGeolocation, {
    Location,
    State,
    Subscription,
} from './capacitor-background-geolocation';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Injectable()
export class StartEffects {
    private subscriptions: Subscription[];

    enabled = false;
    initialized = false;
    readyForUpdate = true;
    vehicle?: Vehicle;
    employee?: Employee;

    constructor(
        private injector: Injector,
        private action$: Actions,
        private navCtrl: NavController,
        private router: Router,
        private store: Store<any>,
        private zone: NgZone
    ) {
        this.subscriptions = [];
    }

    removeEmployeeFromVehicle$ = createEffect(
        () =>
            this.action$.pipe(
                ofType(removeEmployeeFromVehicle),
                tap((data) => {
                    this.vehicle = data.vehicle;
                    this.employee = data.employee;
                    this.enabled = false;
                    this.onToggleEnabled();
                    this.injector
                        .get(VehicleEntityService)
                        .leaveTeam()
                        .subscribe(() => {
                            this.injector
                                .get(VehicleEntityService)
                                .load()
                                .subscribe(() => {
                                    this.injector
                                        .get(AuthService)
                                        .getCurrentWorkTime()
                                        .subscribe((currentW) => {
                                            this.store.dispatch(
                                                setCurrentWorktime({
                                                    currentWorktime: currentW,
                                                })
                                            );
                                            this.injector
                                                .get(TransportEntityService)
                                                .load();
                                            this.navCtrl.navigateBack([
                                                'tabs',
                                                'start',
                                            ]);
                                        });
                                });
                        });
                })
            ),
        { dispatch: false }
    );

    employeeAddedToVehicle$ = createEffect(
        () =>
            this.action$.pipe(
                ofType(employeeAddedToVehicle),
                tap((data) => {
                    this.vehicle = data.vehicle;
                    this.employee = data.employee;
                    this.enabled = true;
                    this.onToggleEnabled();
                    this.configureBackgroundGeolocation();
                    this.initialized = true;
                })
            ),
        { dispatch: false }
    );

    subscribe(subscription: Subscription): void {
        this.subscriptions.push(subscription);
    }

    unsubscribe(): void {
        this.subscriptions.forEach((subscription) => subscription.remove());
        this.subscriptions = [];
    }

    async configureBackgroundGeolocation(): Promise<void> {
        if (this.initialized) {
            return;
        }
        // Step 1:  Listen to BackgroundGeolocation events.
        this.subscribe(
            BackgroundGeolocation.onLocation(this.onLocation.bind(this))
        );

        // Step 2:  Configure the plugin
        BackgroundGeolocation.ready({
            reset: true,
            debug: false,
            logLevel: BackgroundGeolocation.LOG_LEVEL_VERBOSE,
            distanceFilter: 10,
            stopTimeout: 1,
            stopOnTerminate: false,
            startOnBoot: true,
            // [Android] backgroundPermissionRationale for Always permission.
            backgroundPermissionRationale: {
                title:
                    // tslint:disable-next-line:quotemark
                    '{applicationName} immer den Zugriff auf den Standort erlauben, auch im Hintergrund.',
                message:
                    '{applicationName} nutzt Ihre Standortdaten, um den aktuellen Standort des Fahrzeugs zu ermitteln, auch wenn die Anwendung geschlossen oder nicht in Verwendung ist. Die Daten werden nur erfasst, während Sie einem Team zugewiesen sind.',
                positiveAction:
                    'Ändern auf "{backgroundPermissionOptionLabel}"',
                negativeAction: 'Abbrechen',
            },
            autoSync: true,
        }).then((state: State) => {
            // Update UI state (toggle switch, changePace button)
            this.zone.run(() => {
                this.enabled = state.enabled;
            });
            this.enabled = true;
            this.onToggleEnabled();
        });
    }

    onToggleEnabled(): void {
        if (this.enabled) {
            BackgroundGeolocation.start();
        } else {
            BackgroundGeolocation.stop();
        }
    }

    onLocation(location: Location): void {
        if (this.vehicle?.employee1 && this.vehicle.employee2) {
            if (this.vehicle.employee2.id === this.employee?.id) {
                return;
            }
        }
        if (!this.enabled) {
            return;
        }
        if (
            this.vehicle?.employee1?.id !== this.employee?.id &&
            this.vehicle?.employee2?.id !== this.employee?.id
        ) {
            return;
        }
        if (location.coords) {
            if (!this.readyForUpdate) {
                return;
            }
            if (!this.injector.get(AuthService).currentUserValue) {
                this.store.dispatch(logout());
                this.router.navigate(['/anmelden']);
                return;
            }
            this.readyForUpdate = false;
            setTimeout(() => {
                this.readyForUpdate = true;
            }, 2000);
            const geoLocation: GeoLocationModel = {
                latitude: location.coords.latitude,
                longitude: location.coords.longitude,
                timestamp: moment().unix(),
            };

            this.injector
                .get(GeoLocationEntityService)
                .addGeoLocation(geoLocation)
                .subscribe();
            return;
        }
    }
}
