import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {
    currentEntityUpdated,
    FileParts,
    ItemType,
    selectUploadFileCompleted,
    selectUploadFileError,
    selectUploadFileFailed,
    selectUploadFileInProgress,
    selectUploadFileProgress,
    selectUploadFileReady,
    UploadCancelAction,
    UploadRequestAction,
    UploadResetAction,
} from '@sansys/crosslib';
import { Camera, PictureSourceType } from '@ionic-native/camera/ngx';
import { select, Store } from '@ngrx/store';
import { faCamera } from '@fortawesome/pro-regular-svg-icons';
import { Observable, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { IS_MOBILE } from '../../platform.injection-token';
import { getCameraOptions } from '../../camera.options';
import { File as NativeFile, FileEntry } from '@ionic-native/file/ngx';
import { ActionSheetController } from '@ionic/angular';
import { DOC_ORIENTATION, NgxImageCompressService } from 'ngx-image-compress';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { translate } from '@ngneat/transloco';

export function getFileReader(): FileReader {
    const fileReader = new FileReader();
    const zoneOriginalInstance = (fileReader as any)[
        '__zone_symbol__originalInstance'
    ];
    return zoneOriginalInstance || fileReader;
}
@Component({
    selector: 'sys-image-uploader[entity][type][fileType]',
    templateUrl: './image-uploader.component.html',
    styleUrls: ['./image-uploader.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageUploaderComponent implements OnInit, OnDestroy {
    @Input() buttonText = 'Bild ändern';
    @Input() showPreview = true;
    @Input() entity!: any;
    @Input() type!: ItemType;
    @Input() fileType!: string;
    @Output() imageUpload = new EventEmitter<FileParts>();

    private subscription = new Subscription();
    localUrl: any;
    localCompressedURl: any;

    faCamera = faCamera;

    fileLink: string | ArrayBuffer | undefined | null = '';

    progress$: Observable<number> = new Observable<number>();
    error$: Observable<string> = new Observable<string>();
    isInProgress$: Observable<boolean> = new Observable<boolean>();
    isReady$: Observable<boolean> = new Observable<boolean>();
    hasFailed$: Observable<boolean> = new Observable<boolean>();

    constructor(
        private camera: Camera,
        private nativeFile: NativeFile,
        private actionSheetController: ActionSheetController,
        private cdr: ChangeDetectorRef,
        private imageCompress: NgxImageCompressService,
        private store: Store<any>,
        private spinner: SpinnerVisibilityService,
        @Inject(IS_MOBILE) public isMobile: boolean
    ) {}

    /* dataUrltoBlob() get the dataUri of a file and return a Blob object of that file*/
    dataURItoBlob(dataURI: string, fileType: string): Blob {
        const byteString = window.atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        return new Blob([int8Array], { type: fileType });
    }

    uploadImage(fileParts: FileParts): void {
        console.log('######### UPLOAD FILE #########');
        if (this.entity.id && this.type !== 'materialLocation') {
            this.spinner.hide();
            this.store.dispatch(
                new UploadRequestAction({
                    file: fileParts.file,
                    item: this.entity,
                    type: this.type,
                    fileType: 'profileImage',
                    fileName: fileParts.name,
                })
            );
        } else {
            const reader = getFileReader();
            reader.onload = (e) => {
                if (e.target?.result) {
                    this.fileLink = e.target.result;
                    this.cdr.detectChanges();
                }
            };
            reader.readAsDataURL(fileParts.file);
            this.imageUpload.emit(fileParts);
            this.spinner.hide();
        }
    }

    takeImage(sourceType: PictureSourceType): void {
        console.log('######### TAKE IMAGE #########');
        this.camera.getPicture(getCameraOptions(this.camera, sourceType)).then(
            (imageData) => {
                console.log('######### WITHIN GETPICTURE #########');
                this.nativeFile
                    .resolveLocalFilesystemUrl(imageData)
                    .then((entry) => {
                        (entry as FileEntry).file((file) => {
                            this.readFile(file);
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            (err) => {
                console.log('############## image taken error: ##############');
                console.log(JSON.stringify(err));
                console.log('############## :image taken error ##############');
            }
        );
    }

    ngOnInit(): void {
        this.subscription.add(
            this.store
                .pipe(
                    select(selectUploadFileCompleted),
                    filter((updated) => updated),
                    tap(() => {
                        this.store.dispatch(
                            currentEntityUpdated({
                                entityType: this.type,
                                id: this.entity.id,
                            })
                        );
                        this.spinner.hide();
                        this.resetUpload();
                    })
                )
                .subscribe()
        );
        this.progress$ = this.store.pipe(select(selectUploadFileProgress));
        this.error$ = this.store.pipe(select(selectUploadFileError));
        this.isInProgress$ = this.store.pipe(
            select(selectUploadFileInProgress)
        );
        this.isReady$ = this.store.pipe(select(selectUploadFileReady));
        this.hasFailed$ = this.store.pipe(select(selectUploadFileFailed));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    resetUpload(): void {
        this.store.dispatch(new UploadResetAction());
    }

    cancelUpload(): void {
        this.store.dispatch(new UploadCancelAction());
    }

    /*setting DOC_ORIENTATION=-2 (unset) to default implies , that the orientaion of the image will remain as the orignal image orientation*/
    checkAndCompressFile(
        image: string,
        fileName: string,
        fileType: string,
        orientation: DOC_ORIENTATION = -2
    ): void {
        // Getting the image size and convet the resulted bytes number to MBs.
        const imageSize = parseFloat(
            (this.imageCompress.byteCount(image) / (1024 * 1024)).toFixed(5)
        );
        if (imageSize <= 1) {
            this.spinner.hide();
            // If the image is less than or equal to 1 MB, there will be no compression
            const imageBlob = this.dataURItoBlob(image.split(',')[1], fileType);
            const fileParts: FileParts = { file: imageBlob, name: fileName };
            this.uploadImage(fileParts);
            return;
        }

        // If image is greater than 1 MB, compressing the image and uploading
        this.imageCompress
            .compressFile(image, orientation, 70, 70)
            .then((result: any) => {
                const imgResultAfterCompress = result;
                this.localCompressedURl = result;

                // the compressFile function returns the compressed image as dataURi/base64 string.
                // converting that string to a Blob object to upload.

                const imageBlob = this.dataURItoBlob(
                    imgResultAfterCompress.split(',')[1],
                    fileType
                );
                const fileParts: FileParts = {
                    file: imageBlob,
                    name: fileName,
                };
                this.spinner.hide();
                this.uploadImage(fileParts);
            })
            .catch((error: any) => {
                this.spinner.hide();
                console.log(error);
            });
    }

    readFile(file: File): void {
        // this.spinner.show();
        const reader = getFileReader();
        reader.onload = (event: any) => {
            // this.spinner.hide();
            this.cdr.detectChanges();
            /*on reader load file as dataURL, doing image compression on the file*/
            this.checkAndCompressFile(
                event.target?.result,
                file.name,
                file.type
            );
        };
        reader.onerror = (error) => {
            console.log('on file load error', error);
            // this.spinner.hide();
        };
        reader.readAsDataURL(file);
    }

    getFileFromInput(args: Event): void {
        const files = (args.target as HTMLInputElement).files;
        if (!files) {
            return;
        }
        const file = files.item(0);
        if (!file) {
            return;
        }
        this.readFile(file);
    }

    async showSelectImageNotice(): Promise<void> {
        const actionSheet = await this.actionSheetController.create({
            header: translate('Quelle auswählen'),
            buttons: [
                {
                    text: translate('Gallerie'),
                    icon: 'image',
                    handler: () => {
                        this.takeImage(
                            this.camera.PictureSourceType.PHOTOLIBRARY
                        );
                    },
                },
                {
                    text: translate('Kamera'),
                    icon: 'camera',
                    handler: () => {
                        this.takeImage(this.camera.PictureSourceType.CAMERA);
                    },
                },
                {
                    text: translate('Abbrechen'),
                    role: 'cancel',
                },
            ],
        });
        await actionSheet.present();
    }
}
