import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: 'login',
        loadChildren: () =>
            import('./features/login/login.module').then(
                (m) => m.LoginPageModule
            ),
    },
    {
        path: 'tabs',
        loadChildren: () =>
            import('./features/tabs/tabs.module').then((m) => m.TabsPageModule),
    },
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'login',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
