<ion-toolbar>
    <ion-buttons slot="secondary">
        <ion-button (click)="closeModal()">
            <fa-icon [icon]="faTimes" size="lg"></fa-icon>
        </ion-button>
    </ion-buttons>
    <ion-title>{{ title }}</ion-title>
    <!--        <ion-buttons slot="primary">-->
    <!--            <ion-button (click)="save()">-->
    <!--                <fa-icon [icon]="faSave" size="lg"></fa-icon>-->
    <!--            </ion-button>-->
    <!--        </ion-buttons>-->
</ion-toolbar>

<ion-content class="ion-padding quick-functions__content">
    <ion-list lines="full">
        <ion-item *ngFor="let input of inputs">
            <ion-label position="stacked"
                >{{ input.placeholder
                }}<span *ngIf="input.required">*</span></ion-label
            >
            <ion-input
                *ngIf="input.type !== 'select'"
                [type]="getInputType(input)"
                [min]="getMin(input)"
                [required]="!!input.required"
                [max]="getMax(input)"
                [placeholder]="input.placeholder"
                [(ngModel)]="model[input.name || '']"
            ></ion-input>
            <ion-select
                *ngIf="input.type === 'select'"
                [(ngModel)]="model[input.name || '']"
                [required]="!!input.required"
                [placeholder]="input.placeholder"
                cancelText="{{ 'Abbrechen' | transloco }}"
            >
                <ion-select-option
                    *ngFor="let selectOption of input.selectOptions"
                    [value]="selectOption.value"
                    >{{ selectOption.label }}</ion-select-option
                >
            </ion-select>
        </ion-item>
    </ion-list>

    <ion-button
        (click)="save()"
        color="success"
        expand="block"
        class="ion-margin-top"
        [disabled]="missingRequiredField()"
    >
        Speichern
    </ion-button>

    <ion-button
        *ngFor="let button of buttons"
        (click)="handleClick(button.handler)"
        expand="block"
        [color]="getButtonColor(button)"
        class="ion-margin-top"
    >
        {{ button.text }}
    </ion-button>
</ion-content>
