import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { FormControl } from '@angular/forms';
import { faList } from '@fortawesome/pro-regular-svg-icons';
import { ModalController } from '@ionic/angular';
import { SelectLocationComponent } from '../select-location/select-location.component';

@Component({
    selector: 'sys-location-input',
    template: `
        <ion-item>
            <ion-label position="stacked">{{ to.label }}</ion-label>
            <ion-input
                [formControl]="getFormControl()"
                [formlyAttributes]="field"
                [placeholder]="to.placeholder"
            ></ion-input>
            <ion-button
                color="dark"
                slot="end"
                class="button-within-input ion-margin-end"
                (click)="showLocationOptions()"
            >
                <fa-icon [icon]="faList" size="lg"></fa-icon>
            </ion-button>
            <sys-scanner-button
                slot="end"
                class="button-within-input"
                [scanRequestSource]="'location'"
                (scannedCode)="updateInputValue($event)"
            ></sys-scanner-button>
        </ion-item>
    `,
})
export class LocationInputTypeComponent extends FieldType {
    faList = faList;
    constructor(private modalCtrl: ModalController) {
        super();
    }

    getFormControl(): FormControl {
        return this.formControl as FormControl;
    }

    updateInputValue(args: any): void {
        if (args.type === 'location') {
            this.formControl.setValue(args.value);
        }
    }

    async showLocationOptions(): Promise<void> {
        const modal = await this.modalCtrl.create({
            cssClass: 'quick-info-panel',
            component: SelectLocationComponent,
            componentProps: {
                type: this.to.ItemTypeType,
                locationType:
                    this.to.ItemTypeType === 'material' ? 'location' : 'home',
                updateMode: false,
            },
        });
        modal.onWillDismiss().then((args) => {
            if (args.data && args.data['selectedLocation']) {
                this.updateInputValue(args.data['selectedLocation']);
            }
        });
        return await modal.present();
    }
}
