import { Inject, Injectable } from '@angular/core';
import { API_BASE_FILE_SERVER, FileUpload, ItemType } from '@sansys/crosslib';
import { ImageDetailsComponent } from './image-details/image-details.component';
import { ModalController, Platform } from '@ionic/angular';
import { IS_MOBILE } from './platform.injection-token';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Injectable({ providedIn: 'root' })
export class FilesService {
    constructor(
        private modalController: ModalController,
        private iab: InAppBrowser,
        private platform: Platform,
        @Inject(IS_MOBILE) public isMobile: boolean,
        @Inject(API_BASE_FILE_SERVER) private fileServer: string
    ) {}

    async showUploadImage(
        entity: any,
        type: ItemType,
        fileType: string,
        file?: FileUpload
    ): Promise<void> {
        const modal = await this.modalController.create({
            component: ImageDetailsComponent,
            componentProps: { entity, type, file, fileType },
        });
        return await modal.present();
    }

    async openFile(url: string): Promise<void> {
        url = this.fileServer + url;
        if (!this.isMobile) {
            window.open(url, '_blank');
            return;
        }

        if (this.platform.is('android')) {
            window.open(url, '_system');
        } else {
            this.iab.create(url);
        }
    }
}
