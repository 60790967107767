import { Inject, Injectable, Injector } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { tap, switchMapTo, map } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';
import { Action, Store } from '@ngrx/store';
import {
    API_BASE_URL_TOKEN,
    AuthService,
    CompanyService,
    errorOccured,
    EventsService,
    initialCheckAuth,
    LocalStorageService,
    logout,
    notificationReceived,
    ONESIGNAL_ID,
    setAccessRights,
    setCurrentWorktime,
    softLogin,
    TariffEntityService,
    userLogin,
} from '@sansys/crosslib';
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ModalController, NavController, Platform } from '@ionic/angular';
// @ts-ignore
import OneSignal from 'onesignal-cordova-plugin';
import { UUID } from 'angular2-uuid';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { AndroidGeoModalComponent } from './android-geo-modal/android-geo-modal.component';

@Injectable()
export class AuthEffects implements OnInitEffects {
    constructor(
        private injector: Injector,
        private translocoService: TranslocoService,
        private authService: AuthService,
        private action$: Actions,
        private router: Router,
        private permissionsService: NgxPermissionsService,
        private companyService: CompanyService,
        private store: Store<any>,
        private eventsService: EventsService,
        private navController: NavController,
        private appVersion: AppVersion,
        private localStorageService: LocalStorageService,
        private platform: Platform,
        private modalController: ModalController,
        private http: HttpClient,
        @Inject(API_BASE_URL_TOKEN) private server: string,
        @Inject(ONESIGNAL_ID) private oneSignalId: string
    ) {}

    login$ = createEffect(
        () =>
            this.action$.pipe(
                ofType(userLogin),
                tap((action) => {
                    console.log('User Login start');
                    this.checkVersion();
                    // const activeLanguage = action.currentUser?.user?.company
                    //     ?.companyType
                    //     ? action.currentUser.user.company.companyType
                    //     : 'standard';
                    // this.translocoService.setActiveLang(activeLanguage);

                    if (action.currentUser.transportLocation) {
                        this.manuallyInjectEntityService().fetchAndStoreOwnTariff();
                    }

                    this.authService
                        .getCurrentWorkTime()
                        .subscribe((currentWorktime) =>
                            this.store.dispatch(
                                setCurrentWorktime({ currentWorktime })
                            )
                        );

                    this.authService
                        .getMobilePermissions()
                        .subscribe((accessRights) => {
                            if (accessRights.isAdmin) {
                                this.permissionsService.addPermission('admin');
                            }
                            accessRights.permissions.forEach((permission) => {
                                const permissionString =
                                    permission.category +
                                    '|' +
                                    permission.action;
                                this.permissionsService.addPermission(
                                    permissionString
                                );
                            });
                            this.store.dispatch(
                                setAccessRights({ accessRights })
                            );
                        });

                    if (action.redirectToHome) {
                        this.router.navigate(['/tabs/start']);
                    }

                    this.companyService
                        .getPrimaryColor()
                        .subscribe((primaryColor) => {
                            this.companyService.setColors(primaryColor);
                        });

                    if (action.currentUser?.user?.company?.id) {
                        this.eventsService.subscribeToEventStream(
                            action.currentUser.user.company.id
                        );
                    }

                    console.log('####### About to login user');

                    setTimeout(() => {
                        this.initializeOneSignal();
                    }, 1000);

                    if (this.platform.is('android')) {
                        const optionSet = !!this.localStorageService.getItem(
                            'androidAllowGeoLocation'
                        );
                        if (!optionSet) {
                            this.showAndroidGeoModal();
                        }
                    }
                })
            ),
        { dispatch: false }
    );

    softLogin$ = createEffect(() =>
        this.action$.pipe(
            ofType(softLogin),
            switchMapTo(this.authService.softLogin()),
            map((currentUser) => {
                if (!currentUser) {
                    return logout();
                }
                return userLogin({ currentUser });
            })
        )
    );

    logout$ = createEffect(
        () =>
            this.action$.pipe(
                ofType(logout),
                tap(() => {
                    this.authService.logout();
                    this.eventsService.unsubscribeFromEventStream();
                })
            ),
        { dispatch: false }
    );

    initialAuthCheck$ = createEffect(
        () =>
            this.action$.pipe(
                ofType(initialCheckAuth),
                switchMapTo(this.authService.checkAuthentication())
            ),
        { dispatch: false }
    );

    private manuallyInjectEntityService(): TariffEntityService {
        return this.injector.get(TariffEntityService);
    }

    private sendUserIdToServer(
        userId: string,
        action: 'add' | 'remove'
    ): Observable<any> {
        console.log('########################  ##################');
        console.log(action, userId);
        console.log('########################  ##################');
        return this.http.post<null>(`${this.server}api/notificationdevices/`, {
            userId,
            action,
        });
    }

    // private onPushReceived(payload: OSNotificationPayload): void {
    //     const data = payload as any;
    //     this.store.dispatch(
    //         notificationReceived({ notificationContent: data, isActive: true })
    //     );
    //     console.log('ON PUSH RECEIVED');
    // }

    private onPushOpened(title: string): void {
        this.store.dispatch(
            notificationReceived({
                notificationContent: { data: { type: 'rapport' }, id: title },
                isActive: true,
            })
        );
        console.log('ON PUSH OPENED');
        if (title === 'Neue Nachricht') {
            this.navController.navigateForward('/tabs/start/rapports');
        }
    }

    private initializeOneSignal(): void {
        console.log('#########initilizing One Signal');

        OneSignal.setAppId(this.oneSignalId);

        console.log('#########this.oneSignalId: ' + this.oneSignalId);

        OneSignal.setNotificationOpenedHandler((data) => {
            console.log(
                'notificationOpenedCallback: ' +
                    JSON.stringify(data.notification.rawPayload)
            );
            if (data.notification?.title) {
                this.onPushOpened(data.notification.title);
            }
        });

        // iOS - Prompts the user for notification permissions.
        OneSignal.promptForPushNotificationsWithUserResponse((accepted) => {
            console.log('User accepted notifications: ' + accepted);
        });

        // this.oneSignal.inFocusDisplaying(
        //     this.oneSignal.OSInFocusDisplayOption.Notification
        // );

        // OneSignal.().subscribe((data) => {
        //     if (data.payload) {
        //         this.onPushReceived(data.payload);
        //     }
        // });

        // this.oneSignal.handleNotificationOpened().subscribe((data) => {
        //     if (data.notification.payload) {
        //         this.onPushOpened(data.notification.payload);
        //     }
        // });
        // this.oneSignal.endInit();

        const uuid = UUID.UUID();

        OneSignal.setExternalUserId(uuid);

        this.sendUserIdToServer(uuid, 'add').subscribe();

        // OneSignal.getDeviceState()
        //     .then((deviceState) => {
        //         console.log('############ get Ids');
        //         console.log(JSON.stringify(deviceState));
        //         this.sendUserIdToServer(deviceState.userId, 'add').subscribe();
        //     })
        //     .catch((err) => {
        //         console.log('############ ERR');
        //         console.log(err);
        //     });

        OneSignal.addSubscriptionObserver((stateChanges) => {
            console.log(
                'Push subscription state changed: ' +
                    JSON.stringify(stateChanges, null, 2)
            );
            if (stateChanges.to.userId) {
                this.sendUserIdToServer(
                    stateChanges.to.userId,
                    'add'
                ).subscribe();
            }
        });

        console.log('############ end init');
    }

    ngrxOnInitEffects(): Action {
        return initialCheckAuth();
    }

    private checkVersion(): void {
        this.companyService.getAppVersion().subscribe((availableVersion) => {
            this.appVersion.getVersionCode().then((installedVersion) => {
                this.authService.updateAppVersion(installedVersion).subscribe();
                if (availableVersion > installedVersion) {
                    this.store.dispatch(
                        errorOccured({
                            title: 'Info',
                            message: 'Neue App-Version verfügbar.',
                        })
                    );
                }
            });
        });
    }

    private async showAndroidGeoModal(): Promise<void> {
        const modal = await this.modalController.create({
            component: AndroidGeoModalComponent,
        });
        return await modal.present();
    }
}
