import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    orderDetails,
    orderHistory,
    showCompany,
    showImageDetails,
    showScanner,
    showStocktakingPage,
    showUserDetails,
    showUsers,
} from '@sansys/crosslib';
import { tap } from 'rxjs/operators';
import { FilesService } from './files.service';
import { NavigationExtras } from '@angular/router';
import { NavController } from '@ionic/angular';
import { scannerResultAvailable } from './scanner.actions';
import { ScannerService } from './scanner/scanner.service';

@Injectable()
export class CommonEffects {
    constructor(
        private action$: Actions,
        private commonService: FilesService,
        private scannerService: ScannerService,
        private navController: NavController
    ) {}

    showUpdateImage$ = createEffect(
        () =>
            this.action$.pipe(
                ofType(showImageDetails),
                tap((data) => {
                    this.commonService.showUploadImage(
                        data.item,
                        data.ligType,
                        data.fileType,
                        data.file
                    );
                })
            ),
        { dispatch: false }
    );

    showScanner$ = createEffect(
        () =>
            this.action$.pipe(
                ofType(showScanner),
                tap((data) => {
                    this.scannerService.scanCode(data.action, data.itemType);
                })
            ),
        { dispatch: false }
    );

    // login$ = createEffect(
    //     () =>
    //         this.action$.pipe(
    //             ofType(login),
    //             tap(() => {
    //                 this.commonService.setAppSettings();
    //             })
    //         ),
    //     { dispatch: false }
    // );

    processActionScannerResult$ = createEffect(
        () =>
            this.action$.pipe(
                ofType(scannerResultAvailable),
                tap((data) => {
                    this.scannerService.processActionScannerResult(
                        data.scannedCode,
                        data.scanRequestSource,
                        data.itemType
                    );
                })
            ),
        { dispatch: false }
    );

    showStocktaking$ = createEffect(
        () =>
            this.action$.pipe(
                ofType(showStocktakingPage),
                tap(() => {
                    this.navController.navigateForward('/stocktaking');
                })
            ),
        { dispatch: false }
    );

    showMaterialOrderList$ = createEffect(
        () =>
            this.action$.pipe(
                ofType(orderHistory),
                tap(() => {
                    this.navController.navigateForward('orderslist');
                })
            ),
        { dispatch: false }
    );

    showMaterialOrderDetails$ = createEffect(
        () =>
            this.action$.pipe(
                ofType(orderDetails),
                tap((data) => {
                    const navigationExtras: NavigationExtras = {
                        queryParams: {
                            materialOrderId: data.materialOrderId,
                        },
                    };
                    this.navController.navigateForward(
                        'orderslist',
                        navigationExtras
                    );
                })
            ),
        { dispatch: false }
    );

    showCompany$ = createEffect(
        () =>
            this.action$.pipe(
                ofType(showCompany),
                tap(() => {
                    this.navController.navigateForward('company');
                })
            ),
        { dispatch: false }
    );

    showUsersList$ = createEffect(
        () =>
            this.action$.pipe(
                ofType(showUsers),
                tap(() => {
                    this.navController.navigateForward('userslist');
                })
            ),
        { dispatch: false }
    );

    showUserDetails$ = createEffect(
        () =>
            this.action$.pipe(
                ofType(showUserDetails),
                tap((data) => {
                    const navigationExtras: NavigationExtras = {
                        queryParams: {
                            own: data.own,
                            userId: data.userId,
                        },
                    };
                    this.navController.navigateForward(
                        'userdetails',
                        navigationExtras
                    );
                })
            ),
        { dispatch: false }
    );
}
