import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
    selector: 'sys-formly-wrapper-panel',
    template: `
        <ion-item-divider class="pt-4 pb-4">
            {{ to.heading }}
            <p class="sys--display-contents"><br />{{ to.subHeading }}</p>
        </ion-item-divider>
        <ng-container #fieldComponent></ng-container>
    `,
})
export class FormlyGroupWrapperComponent extends FieldWrapper {
    constructor() {
        super();
    }
}
