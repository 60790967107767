import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'sys-ionic-textarea',
    template: `
        <ion-item>
            <ion-label position="stacked">{{ to.label }}</ion-label>
            <ion-textarea
                [autoGrow]="true"
                autocapitalize="sentences"
                [placeholder]="to.placeholder"
                [formControl]="formControl"
            ></ion-textarea>
        </ion-item>
    `,
})
export class IonicTextareaTypeComponent extends FieldType {
    public formControl!: FormControl;
}
