import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { faScanner } from '@fortawesome/pro-regular-svg-icons';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { Subscription } from 'rxjs';
import { ScannerType } from '@sansys/crosslib';
import { getScannerResult, scannerResultAvailable } from '../scanner.actions';

@Component({
    selector: 'sys-scanner-button',
    templateUrl: './scanner-button.component.html',
    styleUrls: ['./scanner-button.component.scss'],
})
export class ScannerButtonComponent implements OnInit, OnDestroy {
    @Output() scannedCode = new EventEmitter<{
        type: ScannerType;
        value: string;
    }>();
    @Input() scanRequestSource: ScannerType = 'form';
    private subscription = new Subscription();

    faScanner = faScanner;

    constructor(private actions$: Actions, private store: Store<any>) {}

    ngOnInit(): void {
        this.subscription.add(
            this.actions$
                .pipe(
                    ofType(scannerResultAvailable),
                    tap((data) => {
                        this.scannedCode.emit({
                            type: data.scanRequestSource,
                            value: data.scannedCode,
                        });
                    })
                )
                .subscribe()
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    scanCode(): void {
        this.store.dispatch(
            getScannerResult({ scanRequestSource: this.scanRequestSource })
        );
    }
}
