import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { faTimes, faShapes } from '@fortawesome/pro-regular-svg-icons';
import {
    API_BASE_FILE_SERVER,
    currentEntityUpdated,
    FileUpload,
    ItemType,
    SysBaseEntityType,
} from '@sansys/crosslib';
import { Actions, ofType } from '@ngrx/effects';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'sys-image-details[entity][type][fileType]',
    templateUrl: './image-details.component.html',
    styleUrls: ['./image-details.component.scss'],
})
export class ImageDetailsComponent implements OnInit, OnDestroy {
    @Input() entity!: Partial<SysBaseEntityType>;
    @Input() type!: ItemType;
    @Input() file?: FileUpload;
    @Input() fileType!: string;
    private subscription = new Subscription();

    faTimes = faTimes;
    faShapes = faShapes;

    constructor(
        private modalController: ModalController,
        private action$: Actions,
        @Inject(API_BASE_FILE_SERVER) public server: string
    ) {}

    ngOnInit(): void {
        this.subscription.add(
            this.action$
                .pipe(
                    ofType(currentEntityUpdated),
                    tap(() => {
                        this.modalController.dismiss();
                    })
                )
                .subscribe()
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    closeModal(): void {
        this.modalController.dismiss();
    }
}
