import { Injectable, Injector } from '@angular/core';
import {
    AlertController,
    ModalController,
    NavController,
} from '@ionic/angular';
import {
    currentEntityUpdated,
    errorOccured,
    Facility,
    Material,
    MaterialEntityService,
    MaterialListFilter,
    MaterialLocation,
    MaterialLocationEntityService,
    MaterialProperty,
    Vehicle,
} from '@sansys/crosslib';
import { translate } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { NavigationExtras } from '@angular/router';
import { AlertButton } from '@ionic/core/dist/types/components/alert/alert-interface';
import { InputAlertComponent } from '../../../../common/input-alert/input-alert.component';
import { SysAlertInput } from '../../../../common/input-alert/alert-input.model';

@Injectable({ providedIn: 'root' })
export class MaterialService {
    constructor(
        private navController: NavController,
        private alertController: AlertController,
        private store: Store<any>,
        private injector: Injector,
        private modalController: ModalController
    ) {}

    private addLocation(
        material: Material,
        assignedId: string,
        name: string,
        amount?: string,
        stockMin?: string,
        stockMax?: string
    ): void {
        if (!material.id) {
            this.store.dispatch(
                errorOccured({
                    message: 'Material Id is missing',
                })
            );
            return;
        }
        const newLocation: MaterialLocation = {
            name,
            materialId: material.id,
            currentStock: 0,
            assignedId,
        };
        if (amount) {
            newLocation.currentStock = parseFloat(amount);
        }
        if (stockMin) {
            newLocation.minStock = parseFloat(stockMin);
        }
        if (stockMax) {
            newLocation.maxStock = parseFloat(stockMax);
        }
        this.injector
            .get(MaterialLocationEntityService)
            .add(newLocation)
            .subscribe(() => {
                this.injector
                    .get(MaterialEntityService)
                    .getByKey(material.id)
                    .subscribe(() => {
                        if (material.id) {
                            this.store.dispatch(
                                currentEntityUpdated({
                                    entityType: 'material',
                                    id: material.id,
                                })
                            );
                        }
                    });
                this.modalController.dismiss();
            });
    }

    showAddMaterialPage(isAutoMode: boolean = false): void {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                isAutoMode,
            },
        };
        this.navController.navigateForward('/addmaterial', navigationExtras);
    }

    public async showUpdateDataAlert(
        type: MaterialProperty,
        material: Material
    ): Promise<void> {
        const alert = await this.alertController.create({
            header: translate(type),
            inputs: [
                {
                    name: 'name',
                    type: 'text',
                    value: material[type],
                },
            ],
            buttons: [
                {
                    text: translate('Abbrechen'),
                    role: 'cancel',
                },
                {
                    text: translate('Speichern'),
                    handler: (data: { name: string }) => {
                        alert.dismiss();
                        const updatedMaterial = JSON.parse(
                            JSON.stringify(material)
                        );
                        updatedMaterial[type] = data.name;
                        this.injector
                            .get(MaterialEntityService)
                            .update(updatedMaterial);
                        if (material.id) {
                            this.store.dispatch(
                                currentEntityUpdated({
                                    entityType: 'material',
                                    id: material.id,
                                })
                            );
                        }
                    },
                },
            ],
        });

        await alert.present();
    }

    showMaterialDetailsPage(materialId: string): void {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                materialId,
            },
        };
        this.navController.navigateForward(
            '/tabs/warehouse/materialdetails',
            navigationExtras
        );
    }

    showMaterialsList(listOptions: MaterialListFilter): void {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                filter: listOptions,
            },
        };
        if (listOptions === 'toOrderList') {
            this.navController.navigateForward('toorderlist', navigationExtras);
        } else {
            this.navController.navigateForward(
                'materialslist',
                navigationExtras
            );
        }
    }

    async addLocationToMaterial(
        material: Material,
        facilities: Facility[],
        vehicles: Vehicle[]
    ): Promise<void> {
        const saveButton: AlertButton = {
            text: translate('Speichern'),
            handler: (data: {
                name: string;
                amount: string;
                minStock: string;
                maxStock: string;
                assignedId: string;
            }) => {
                this.addLocation(
                    material,
                    data.assignedId,
                    data.name,
                    data.amount,
                    data.minStock,
                    data.maxStock
                );
            },
        };

        const selectOptions: { label: string; value: string }[] = [];

        facilities.forEach((facility) => {
            if (facility?.id) {
                selectOptions.push({
                    label: facility.name,
                    value: facility.id,
                });
            }
        });

        vehicles.forEach((vehicle) => {
            if (vehicle?.id) {
                selectOptions.push({
                    label: vehicle.name,
                    value: vehicle.id,
                });
            }
        });

        const inputs: SysAlertInput[] = [
            {
                name: 'assignedId',
                type: 'select',
                required: true,
                placeholder: translate('Standort/Fahrzeug'),
                selectOptions: selectOptions,
            },
            {
                name: 'name',
                required: true,
                type: 'text',
                placeholder: translate('Name'),
            },
            {
                name: 'amount',
                type: 'number',
                min: 0,
                placeholder: translate('Bestand'),
            },
            {
                name: 'minStock',
                type: 'number',
                min: 1,
                placeholder: translate('Mindestbestand'),
            },
            {
                name: 'maxStock',
                type: 'number',
                min: 1,
                placeholder: translate('Maximalbestand'),
            },
        ];

        const modal = await this.modalController.create({
            component: InputAlertComponent,
            swipeToClose: true,
            showBackdrop: true,
            backdropDismiss: true,
            cssClass: 'quick-info-panel',
            componentProps: {
                title: 'Lagerort anlegen',
                subTitle: 'Für ' + material.name,
                inputs: inputs,
                saveButton: saveButton,
            },
        });

        await modal.present();
    }
}
