import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import { FormControl } from '@angular/forms';
import { Insurance } from '@sansys/crosslib';

@Component({
    selector: 'sys-health-insurance-picker',
    template: `
        <ion-item>
            <ion-label position="stacked">{{
                'Versicherung' | transloco
            }}</ion-label>
            <sys-insurance-picker
                (insuranceSelected)="formControl.setValue($event)"
                [currentInsurance]="selectedInsurance"
            ></sys-insurance-picker>
        </ion-item>
    `,
})
export class IonicHealthInsuranceSelectComponent
    extends FieldType
    implements OnInit {
    formControl!: FormControl;
    selectedInsurance?: Insurance;

    constructor() {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    getFormControl(): FormControl {
        return this.formControl as FormControl;
    }

    updateInputValue(code: string): void {
        this.formControl.setValue(code);
    }
}
