import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ErrorMessageService } from './error-message.service';
import { errorOccured, showRequestConfirmation } from '@sansys/crosslib';
import { SpinnerVisibilityService } from 'ng-http-loader';

@Injectable({ providedIn: 'root' })
export class ErrorEffects {
    constructor(
        private actions$: Actions,
        private errorMessageService: ErrorMessageService,
        private spinner: SpinnerVisibilityService
    ) {}

    errorOccured$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(errorOccured),
                tap(async (error) => {
                    this.spinner.hide();
                    await this.errorMessageService.displayErrorMessage(error);
                })
                // map(() => {
                //     return refresh(); // TODO: Prevent looping errors
                // })
            ),
        { dispatch: false }
    );

    showRequestConfirmation$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(showRequestConfirmation),
                tap((data) => {
                    if (data.requestType === 'worktime') {
                        this.errorMessageService.askForWorktimeReduction(
                            data.originalRequest,
                            data.foundResults
                        );
                    } else {
                        this.errorMessageService.askForConfirmation(
                            data.originalRequest,
                            data.foundResults
                        );
                    }
                })
            ),
        { dispatch: false }
    );
}
