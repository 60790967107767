import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { IonicSelectableComponent } from 'ionic-selectable';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { first, map, tap } from 'rxjs/operators';
import {
    Insurance,
    insuranceCreated,
    InsuranceEntityService,
} from '@sansys/crosslib';
import { Subscription } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { AlertController } from '@ionic/angular';
import { AlertButton } from '@ionic/core/dist/types/components/alert/alert-interface';
import { NgxPermissionsService } from 'ngx-permissions';
import { translate } from '@ngneat/transloco';

@Component({
    selector: 'sys-insurance-picker[currentInsurance]',
    templateUrl: './insurance-picker.component.html',
    styleUrls: ['./insurance-picker.component.scss'],
})
export class InsurancePickerComponent implements OnInit, OnDestroy {
    @Output() insuranceSelected = new EventEmitter<Insurance>();
    @Input() currentInsurance?: Insurance;
    @Input() placeholder = 'Versicherung wählen';
    @Input() insuranceType?: 'KV' | 'UV';
    @ViewChild('insuranceComponent')
    insuranceComponent!: IonicSelectableComponent;

    private subscription = new Subscription();

    insurances: Insurance[] = [];
    insuranceForm: FormGroup;
    nameControl: FormControl;
    streetControl: FormControl;
    zipControl: FormControl;
    cityControl: FormControl;

    canEdit = false;
    canAdd = false;
    canDelete = false;

    constructor(
        private insuranceEntityService: InsuranceEntityService,
        private formBuilder: FormBuilder,
        private permissionService: NgxPermissionsService,
        private action$: Actions,
        private alertController: AlertController
    ) {
        this.nameControl = this.formBuilder.control(null, Validators.required);
        this.streetControl = this.formBuilder.control(
            null,
            Validators.required
        );
        this.zipControl = this.formBuilder.control(null, Validators.required);
        this.cityControl = this.formBuilder.control(null, Validators.required);
        this.insuranceForm = this.formBuilder.group({
            name: this.nameControl,
            street: this.streetControl,
            zip: this.zipControl,
            city: this.cityControl,
        });
    }

    private setInsuranceAndClose(insurance: Insurance): void {
        if (this.insuranceComponent.isOpened) {
            this.currentInsurance = insurance;
            this.insuranceSelected.emit(insurance);
            this.insuranceComponent.close();
        }
    }

    ngOnInit(): void {
        this.permissionService
            .hasPermission(['insurances|EDIT', 'admin'])
            .then((perm) => (this.canEdit = perm));
        this.permissionService
            .hasPermission(['insurances|CREATE', 'admin'])
            .then((perm) => (this.canAdd = perm));
        this.permissionService
            .hasPermission(['insurances|DELETE', 'admin'])
            .then((perm) => (this.canDelete = perm));

        this.subscription.add(
            this.action$
                .pipe(
                    ofType(insuranceCreated),
                    tap((action) => {
                        this.insuranceEntityService.addOneToCache(
                            action.insurance
                        );
                        this.setInsuranceAndClose(action.insurance);
                    })
                )
                .subscribe()
        );
        this.reloadData();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public reloadData(): void {
        const query: any = {};
        if (this.insuranceType) {
            query['insuranceType'] = this.insuranceType;
        }
        this.insuranceEntityService
            .getWithQuery(query)
            .pipe(
                first(),
                map((insurances) =>
                    insurances.map((insurance) => {
                        const ins: Insurance = JSON.parse(
                            JSON.stringify(insurance)
                        );
                        if (!this.insuranceType) {
                            ins.name += ' (' + insurance.insuranceType + ')';
                        }
                        return ins;
                    })
                ),
                tap((insurances) => {
                    this.insurances = insurances;
                    if (this.currentInsurance) {
                        this.currentInsurance = insurances.find(
                            (insurance) =>
                                insurance.id === this.currentInsurance?.id
                        );
                    }
                })
            )
            .subscribe();
    }

    onAddInsurance(event: { component: IonicSelectableComponent }): void {
        // Clean form.
        this.nameControl.reset();
        this.streetControl.reset();
        this.zipControl.reset();
        this.cityControl.reset();

        // Copy search text to insurance name field, so
        // user doesn't have to type again.
        this.nameControl.setValue(event.component.searchText);

        // Show form.
        event.component.showAddItemTemplate();
    }

    onEditInsurance(event: {
        component: IonicSelectableComponent;
        item: Insurance;
    }): void {
        // Fill form.
        this.nameControl.setValue(event.item.name);
        this.streetControl.setValue(event.item.street);
        this.zipControl.setValue(event.item.zip);
        this.cityControl.setValue(event.item.city);

        // Show form.
        event.component.showAddItemTemplate();
    }

    // onDeleteInsurance(event: {
    //     component: IonicSelectableComponent;
    //     item: Insurance;
    // }): void {
    //     // Delete insurance from storage.
    //     this.insuranceEntityService.delete(event.item);
    //
    //     // Delete insurance from list.
    //     event.component.deleteItem(event.item);
    // }

    private async askIfDuplicateAddressIsCorrect(
        newInsurance: Insurance,
        existingInsurances: Insurance[]
    ): Promise<void> {
        const alert = await this.alertController.create({
            header: translate('Mögliche Duplikate gefunden'),
            message: translate('Verwenden oder neu anlegen?'),
            buttons: [],
        });

        const buttons: AlertButton[] = [
            {
                text: translate('Abbrechen'),
                role: 'cancel',
            },
        ];

        existingInsurances.forEach((existingInsurance) => {
            buttons.push({
                text: existingInsurance.name || '',
                handler: () => {
                    this.setInsuranceAndClose(existingInsurance);
                },
            });
        });

        buttons.push({
            text: translate('Neu Anlegen'),
            handler: () => {
                this.insuranceEntityService
                    .add(newInsurance)
                    .subscribe((newTransportLoc) => {
                        this.setInsuranceAndClose(newTransportLoc);
                    });
            },
        });

        alert.buttons = buttons;

        await alert.present();
    }

    addInsurance(): void {
        const insurance: Insurance = {
            name: this.nameControl.value,
            street: this.streetControl.value,
            zip: this.zipControl.value,
            city: this.cityControl.value,
            insuranceType: 'KV',
        };

        const foundInsurances: Insurance[] = [];
        this.insurances.forEach((transportL) => {
            if (
                transportL.street?.toLowerCase() ===
                    insurance.street?.toLowerCase() &&
                transportL.zip === insurance.zip
            ) {
                foundInsurances.push(transportL);
            }
        });

        if (foundInsurances.length > 0) {
            this.askIfDuplicateAddressIsCorrect(insurance, foundInsurances);
            return;
        }

        this.insuranceEntityService.add(insurance).subscribe((newInsurance) => {
            this.setInsuranceAndClose(newInsurance);
        });
    }

    saveInsurance(insurance: Insurance): void {
        const editedInsurance = { ...insurance };
        editedInsurance.name = this.nameControl.value;
        editedInsurance.street = this.streetControl.value;
        editedInsurance.zip = this.zipControl.value;
        editedInsurance.city = this.cityControl.value;
        this.insuranceEntityService
            .update(editedInsurance)
            .subscribe(() => this.reloadData());
        // Show list.
        this.insuranceComponent.hideAddItemTemplate();
    }

    setInsurance(): void {
        this.insuranceSelected.emit(this.currentInsurance);
    }
}
