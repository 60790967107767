<ion-header>
    <ion-toolbar color="tool">
        <ion-buttons slot="secondary">
            <ion-button (click)="closeModal()">
                <fa-icon [icon]="faTimes" size="lg"></fa-icon>
            </ion-button>
        </ion-buttons>
        <ion-title class="pl-40 pr-40">
            {{ type | transloco }} {{ locationType | transloco }}
        </ion-title>
        <ion-buttons slot="primary" *ngIf="updateMode">
            <ion-button (click)="confirmLocation()">
                <fa-icon [icon]="faSave" size="lg"></fa-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-content__height-fix">
    <ion-list lines="full" *ngIf="updateMode">
        <ion-list-header>
            <ion-label> {{ 'Freitext' | transloco }}... </ion-label>
        </ion-list-header>
        <ion-item>
            <ion-label position="stacked">{{
                'Ort eingeben' | transloco
            }}</ion-label>
            <ion-input
                placeholder="{{ 'Ort eingeben' | transloco }}"
                [(ngModel)]="selectedLocation"
            ></ion-input>
            <sys-scanner-button
                slot="end"
                class="button-within-input"
                (scannedCode)="updateInputValue($event.value)"
            ></sys-scanner-button>
        </ion-item>
    </ion-list>
    <ion-list *ngIf="availableLocations.length > 0" lines="full">
        <ion-list-header>
            <ion-label> {{ 'Vorlage wählen' | transloco }}... </ion-label>
            <!--            <ion-button (click)="addNewLocationCollectionItem()">-->
            <!--                <fa-icon [icon]="faPlusCircle" size="lg"></fa-icon>-->
            <!--            </ion-button>-->
        </ion-list-header>
        <ion-radio-group [(ngModel)]="selectedLocation">
            <ion-item
                *ngFor="let location of availableLocations"
                (click)="confirmLocation()"
            >
                <ion-label>{{ location }}</ion-label>
                <ion-radio slot="start" value="{{ location }}"></ion-radio>
            </ion-item>
        </ion-radio-group>
    </ion-list>
</ion-content>
