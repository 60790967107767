import { NgModule } from '@angular/core';
import { InsurancePickerComponent } from './insurance-picker.component';
import { IonicSelectableModule } from 'ionic-selectable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { PipesModule } from '@sansys/crosslib';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
    declarations: [InsurancePickerComponent],
    imports: [
        IonicSelectableModule,
        FormsModule,
        IonicModule,
        ReactiveFormsModule,
        CommonModule,
        PipesModule,
        TranslocoModule,
    ],
    exports: [InsurancePickerComponent],
})
export class InsurancePickerModule {}
