import { createAction, props } from '@ngrx/store';
import { ItemType, ScannerType } from '@sansys/crosslib';

export const getScannerResult = createAction(
    '[Scanner] get result',
    props<{ scanRequestSource: ScannerType }>()
);

export const scannerResultAvailable = createAction(
    '[Scanner] result available',
    props<{
        scannedCode: string;
        scanRequestSource: ScannerType;
        itemType?: ItemType;
    }>()
);
