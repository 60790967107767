<ion-header [translucent]="true">
    <ion-toolbar color="material">
        <ion-buttons slot="secondary">
            <ion-button (click)="closeModal()">
                <fa-icon [icon]="faTimes" size="lg"></fa-icon>
            </ion-button>
        </ion-buttons>
        <ion-title
            >{{ fileType | transloco }} {{ file?.displayedName }}</ion-title
        >
    </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
    <div class="sys--image-center">
        <img
            *ngIf="file"
            [src]="server + file.relativePath"
            alt=""
            class="sys--img-within-div"
        />
        <fa-icon
            *ngIf="!file"
            class="sys--icon-center"
            [icon]="faShapes"
            size="6x"
        ></fa-icon>
    </div>

    <sys-image-uploader
        *ngIf="fileType === 'profileImage'"
        [entity]="entity"
        [type]="type"
        [fileType]="fileType"
    ></sys-image-uploader>
</ion-content>
