import { Component, Input } from '@angular/core';
import {
    BugReportData,
    errorOccured,
    FileParts,
    TrelloService,
} from '@sansys/crosslib';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';

@Component({
    selector: 'sys-bug-report',
    templateUrl: './bug-report.component.html',
    styleUrls: ['./bug-report.component.scss'],
})
export class BugReportComponent {
    @Input() message = '';

    bugReport: BugReportData = {
        topic: '',
    };

    constructor(
        private trelloService: TrelloService,
        private modalController: ModalController,
        private store: Store<any>
    ) {}

    closeModal(): void {
        this.modalController.dismiss();
    }

    sendReport(): void {
        this.bugReport.details += '   |   ' + this.message;
        this.trelloService.sendErrorToTrello(this.bugReport).subscribe(() => {
            this.store.dispatch(
                errorOccured({
                    message: 'Der Fehler wurde erfolgreich übertragen.',
                    title: 'Info',
                })
            );
            this.closeModal();
        });
    }

    fileAdded(file: FileParts): void {
        this.bugReport.screenshot = file.file;
    }
}
