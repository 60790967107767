import { NgModule } from '@angular/core';
import { PassengerPickerComponent } from './passenger-picker.component';
import { IonicSelectableModule } from 'ionic-selectable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { InsurancePickerModule } from '../insurance-picker/insurance-picker.module';

@NgModule({
    declarations: [PassengerPickerComponent],
    imports: [
        IonicSelectableModule,
        FormsModule,
        IonicModule,
        ReactiveFormsModule,
        CommonModule,
        TranslocoModule,
        InsurancePickerModule,
    ],
    exports: [PassengerPickerComponent],
})
export class PassengerPickerModule {}
