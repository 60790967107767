<ionic-selectable
    #passengerComponent
    [(ngModel)]="currentPassenger"
    itemValueField="id"
    [(items)]="passengers"
    [canSearch]="true"
    [canClear]="true"
    [canAddItem]="canAdd"
    [canSaveItem]="canAdd || canEdit"
    addButtonText="{{ 'Neu' | transloco }}"
    clearButtonText="{{ 'Leeren' | transloco }}"
    closeButtonText="{{ 'Zurück' | transloco }}"
    searchPlaceholder="{{ 'Suche' | transloco }}"
    [placeholder]="placeholder"
    (onAddItem)="onAddPassenger($event)"
    (onSaveItem)="onEditPassenger($event)"
    (onSearch)="searchItems($event)"
    (onInfiniteScroll)="getMoreItems($event)"
    (onClose)="setPassenger()"
    searchFailText="{{ 'Kein Eintrag gefunden' | transloco }}"
    itemTextField="fullName"
>
    <ng-template ionicSelectableItemTemplate let-passenger="item">
        <span
            [ngClass]="{
                'sys--danger-text-color-dark': !!passenger.warningMessage
            }"
        >
            {{ passenger.firstName }} {{ passenger.lastName }}
            {{ passenger.birthday | date }}
        </span>
    </ng-template>
    <ng-template
        ionicSelectableAddItemTemplate
        let-passenger="item"
        let-isAdd="isAdd"
    >
        <ion-toolbar class="pt-32">
            <ion-title
                >{{ 'passenger' | transloco }}
                {{
                    isAdd
                        ? ('erstellen' | transloco)
                        : ('bearbeiten' | transloco)
                }}</ion-title
            >
        </ion-toolbar>
        <form [formGroup]="passengerForm" novalidate>
            <ion-list>
                <ion-item>
                    <ion-label position="stacked"
                        >{{ 'Vorname' | transloco }} *</ion-label
                    >
                    <ion-input
                        type="text"
                        formControlName="firstName"
                        autocorrect="off"
                        autocapitalize="sentences"
                    >
                    </ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="stacked"
                        >{{ 'Nachname' | transloco }} *</ion-label
                    >
                    <ion-input
                        type="text"
                        formControlName="lastName"
                        autocorrect="off"
                        autocapitalize="sentences"
                    >
                    </ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="stacked">{{
                        'Altersheim' | transloco
                    }}</ion-label>
                    <ion-input
                        type="text"
                        formControlName="nursingHome"
                        autocorrect="off"
                        autocapitalize="sentences"
                    >
                    </ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="stacked">{{
                        'Strasse' | transloco
                    }}</ion-label>
                    <ion-input
                        type="text"
                        formControlName="street"
                        autocorrect="off"
                        autocapitalize="sentences"
                    >
                    </ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="stacked">{{
                        'PLZ' | transloco
                    }}</ion-label>
                    <ion-input
                        type="text"
                        formControlName="zip"
                        autocorrect="off"
                        autocapitalize="none"
                    >
                    </ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="stacked">{{
                        'Ort' | transloco
                    }}</ion-label>
                    <ion-input
                        type="text"
                        formControlName="city"
                        autocorrect="off"
                        autocapitalize="sentences"
                    >
                    </ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="stacked"
                        >{{ 'Geburtstag' | transloco }} *</ion-label
                    >
                    <ion-input
                        type="date"
                        formControlName="birthday"
                        autocorrect="off"
                        autocapitalize="none"
                    >
                    </ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="stacked">{{
                        'Arbeitgeber' | transloco
                    }}</ion-label>
                    <ion-input
                        type="text"
                        formControlName="employer"
                        autocorrect="off"
                        autocapitalize="sentences"
                    >
                    </ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="stacked">{{
                        'Beistand / Rechnungsempfänger' | transloco
                    }}</ion-label>
                    <ion-textarea
                        [autoGrow]="true"
                        formControlName="representative"
                        autocapitalize="sentences"
                        [disabled]="true"
                    ></ion-textarea>
                </ion-item>
                <ion-item>
                    <ion-label position="stacked">{{
                        'Beistand / Rechnungsempfänger Institution' | transloco
                    }}</ion-label>
                    <ion-input
                        type="text"
                        formControlName="repInstitution"
                        autocorrect="off"
                        autocapitalize="sentences"
                    >
                    </ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="stacked">{{
                        'Beistand / Rechnungsempfänger Vorname' | transloco
                    }}</ion-label>
                    <ion-input
                        type="text"
                        formControlName="repFirstName"
                        autocorrect="off"
                        autocapitalize="sentences"
                    >
                    </ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="stacked">{{
                        'Beistand / Rechnungsempfänger Nachname' | transloco
                    }}</ion-label>
                    <ion-input
                        type="text"
                        formControlName="repLastName"
                        autocorrect="off"
                        autocapitalize="sentences"
                    >
                    </ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="stacked">{{
                        'Beistand / Rechnungsempfänger Strasse' | transloco
                    }}</ion-label>
                    <ion-input
                        type="text"
                        formControlName="repStreet"
                        autocorrect="off"
                        autocapitalize="sentences"
                    >
                    </ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="stacked">{{
                        'Beistand / Rechnungsempfänger PLZ' | transloco
                    }}</ion-label>
                    <ion-input
                        type="text"
                        formControlName="repZip"
                        autocorrect="off"
                        autocapitalize="sentences"
                    >
                    </ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="stacked">{{
                        'Beistand / Rechnungsempfänger Stadt' | transloco
                    }}</ion-label>
                    <ion-input
                        type="text"
                        formControlName="repCity"
                        autocorrect="off"
                        autocapitalize="sentences"
                    >
                    </ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="stacked">{{
                        'Telefon' | transloco
                    }}</ion-label>
                    <ion-input
                        type="text"
                        formControlName="phone"
                        autocorrect="off"
                        autocapitalize="none"
                    >
                    </ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="stacked">{{
                        'Telefon Arbeit' | transloco
                    }}</ion-label>
                    <ion-input
                        type="text"
                        formControlName="phoneWork"
                        autocorrect="off"
                        autocapitalize="none"
                    >
                    </ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="stacked">{{
                        'Krankenversicherung' | transloco
                    }}</ion-label>
                    <sys-insurance-picker
                        (insuranceSelected)="healthInsurance = $event"
                        [currentInsurance]="healthInsurance"
                        [insuranceType]="'KV'"
                        placeholder="{{
                            'Krankenversicherung wählen' | transloco
                        }}"
                    ></sys-insurance-picker>
                </ion-item>
            </ion-list>
        </form>
        <ion-footer>
            <ion-toolbar>
                <ion-row>
                    <ion-col>
                        <ion-button
                            expand="full"
                            (click)="passengerComponent.hideAddItemTemplate()"
                        >
                            {{ 'Abbrechen' | transloco }}
                        </ion-button>
                    </ion-col>
                    <ion-col>
                        <ion-button
                            expand="full"
                            (click)="
                                isAdd
                                    ? addPassenger()
                                    : savePassenger(passenger)
                            "
                            [disabled]="!passengerForm.valid"
                        >
                            {{
                                isAdd
                                    ? ('Erstellen' | transloco)
                                    : ('Speichern' | transloco)
                            }}
                        </ion-button>
                    </ion-col>
                </ion-row>
            </ion-toolbar>
        </ion-footer>
    </ng-template>
</ionic-selectable>
<span class="sys--danger-text-color-dark">
    {{ currentPassenger?.warningMessage }}
</span>
