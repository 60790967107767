import { createAction, props } from '@ngrx/store';
import { Employee, Vehicle } from '@sansys/crosslib';

export const removeEmployeeFromVehicle = createAction(
    '[Home] Remove Employee from Vehicle',
    props<{
        employee: Employee;
        vehicle?: Vehicle;
        all?: boolean;
    }>()
);
