<ionic-selectable
    #stationComponent
    [(ngModel)]="currentStation"
    itemValueField="id"
    itemTextField="stationName"
    [(items)]="stations"
    [canSearch]="true"
    [canClear]="true"
    [canAddItem]="false"
    [canSaveItem]="false"
    clearButtonText="{{ 'Leeren' | transloco }}"
    closeButtonText="{{ 'Zurück' | transloco }}"
    searchPlaceholder="{{ 'Suche' | transloco }}"
    [placeholder]="placeholder"
    (onClose)="setStation()"
    (onOpen)="reloadData()"
    searchFailText="{{ 'Kein Eintrag gefunden' | transloco }}"
>
    <ng-template ionicSelectableItemTemplate let-station="item">
        {{ station.stationName }}
    </ng-template>
</ionic-selectable>
