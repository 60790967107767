import { CameraOptions, PictureSourceType } from '@ionic-native/camera/ngx';
import { Camera } from '@ionic-native/camera/ngx';

export function getCameraOptions(
    camera: Camera,
    sourceType: PictureSourceType,
    saveToPhotoAlbum: boolean = false
): CameraOptions {
    return {
        quality: 100,
        destinationType: camera.DestinationType.FILE_URI,
        encodingType: camera.EncodingType.JPEG,
        mediaType: camera.MediaType.PICTURE,
        sourceType: sourceType,
        saveToPhotoAlbum: saveToPhotoAlbum,
    };
}
