import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { File } from '@ionic-native/file/ngx';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import {
    DefaultDataServiceConfig,
    EntityDataModule,
    EntityDefinitionService,
    HttpUrlGenerator,
} from '@ngrx/data';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
    API_BASE_URL_TOKEN,
    AuthModule,
    JwtInterceptor,
    metaReducers,
    PluralHttpUrlGenerator,
    TranslocoRootModule,
    reducers,
    API_BASE_FILE_SERVER,
    UploadFileStoreModule,
    listOptionsFeatureKey,
    listOptionsReducer,
    PLATFORM,
    HttpRequestInterceptor,
    ONESIGNAL_ID,
    MERCURE_URL,
} from '@sansys/crosslib';
import { registerLocaleData } from '@angular/common';
import { entityMetadata } from '@sansys/crosslib';
import { ErrorEffects } from './common/error-handling/error.effects';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyIonicModule } from '@ngx-formly/ionic';
import { CoreModule } from '@angular/flex-layout';
import { CustomFormlyModule } from './common/formly/custom-formly.module';
import { IonicDateTypeComponent } from './common/formly/ionic-date-type.component';
import { IonicHealthInsuranceSelectComponent } from './common/formly/ionic-health-insurance-select.component';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { IonicSectionWrapperComponent } from './common/formly/ionic-section.wrapper';
import { PreviewAnyFile } from '@ionic-native/preview-any-file/ngx';
import { TransportLocationPickerModule } from './common/transport-location-picker/transport-location-picker.module';
import { PassengerPickerModule } from './common/passenger-picker/passenger-picker.module';
import { Camera } from '@ionic-native/camera/ngx';
import { IS_MOBILE } from './common/platform.injection-token';
import { AuthEffects } from './common/auth.effects';
import localeCh from '@angular/common/locales/de-CH';
import { CustomCommonModule } from './common/custom-common.module';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { ScannerEffects } from './common/scanner.effects';
import { LocationInputTypeComponent } from './common/formly/location-input-type.component';
import { InputWithScannerTypeComponent } from './common/formly/input-with-scanner-type.component';
import { FormlyGroupWrapperComponent } from './common/formly/formly-group-wrapper.component';
import { StartEffects } from './features/tabs/start/start.effects';
import { NgxPermissionsModule } from 'ngx-permissions';
import { StationPickerModule } from './common/station-picker/station-picker.module';
import { IonicTextareaTypeComponent } from './common/formly/ionic-textarea-type.component';
import { NgxImageCompressService } from 'ngx-image-compress';
import { NFC } from '@ionic-native/nfc/ngx';
import { TextTypeComponent } from './common/formly/text-type.component';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { InputWithLinkTypeComponent } from './common/formly/input-with-link-type.component';

const defaultDataServiceConfig: DefaultDataServiceConfig = {
    root: environment.server + 'api/',
};

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        CoreModule,
        HttpClientModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        CustomFormlyModule,
        TranslocoRootModule,
        TransportLocationPickerModule,
        PassengerPickerModule,
        StationPickerModule,
        CustomCommonModule,
        StoreModule.forFeature(listOptionsFeatureKey, listOptionsReducer),
        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
                strictActionSerializability: false,
                strictStateSerializability: true,
            },
        }),
        AuthModule,
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
        }),
        EffectsModule.forRoot([
            ErrorEffects,
            AuthEffects,
            ScannerEffects,
            StartEffects,
        ]),
        EntityDataModule.forRoot({}),
        FormlyModule.forRoot({
            types: [
                {
                    name: 'ionicDatePicker',
                    component: IonicDateTypeComponent,
                },
                {
                    name: 'text',
                    component: TextTypeComponent,
                },
                {
                    name: 'ionicHealthInsuranceSelect',
                    component: IonicHealthInsuranceSelectComponent,
                },
                {
                    name: 'locationInput',
                    component: LocationInputTypeComponent,
                },
                {
                    name: 'inputWithScanner',
                    component: InputWithScannerTypeComponent,
                },
                {
                    name: 'ionicTextarea',
                    component: IonicTextareaTypeComponent,
                },
                {
                    name: 'inputWithLink',
                    component: InputWithLinkTypeComponent,
                },
            ],
            wrappers: [
                {
                    name: 'ionicSectionWrapper',
                    component: IonicSectionWrapperComponent,
                },
                {
                    name: 'groupWrapper',
                    component: FormlyGroupWrapperComponent,
                },
            ],
        }),
        FormlyIonicModule,
        NgHttpLoaderModule.forRoot(),
        NgxPermissionsModule.forRoot(),
        UploadFileStoreModule,
    ],
    providers: [
        StatusBar,
        SplashScreen,
        PreviewAnyFile,
        BarcodeScanner,
        Camera,
        File,
        NFC,
        AppVersion,
        InAppBrowser,
        NgxImageCompressService,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: DefaultDataServiceConfig,
            useValue: defaultDataServiceConfig,
        },
        { provide: LOCALE_ID, useValue: 'ch' }, // Define language for dates and numbers
        { provide: HttpUrlGenerator, useClass: PluralHttpUrlGenerator },
        {
            provide: API_BASE_URL_TOKEN,
            useValue: environment.server,
        },
        {
            provide: ONESIGNAL_ID,
            useValue: environment.oneSignalId,
        },
        {
            provide: PLATFORM,
            useValue: 'employee',
        },
        {
            provide: API_BASE_FILE_SERVER,
            useValue: environment.fileServer,
        },
        {
            provide: MERCURE_URL,
            useValue: environment.mercureUrl,
        },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestInterceptor,
            multi: true,
        },
        {
            provide: IS_MOBILE,
            useValue:
                !document.URL.startsWith('http') ||
                document.URL.startsWith('http://localhost'),
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(private eds: EntityDefinitionService) {
        eds.registerMetadataMap(entityMetadata);
        registerLocaleData(localeCh, 'ch');
    }
}
