import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { IonicSelectableComponent } from 'ionic-selectable';
import { first, tap } from 'rxjs/operators';
import {
    Station,
    StationEntityService,
    TransportLocation,
} from '@sansys/crosslib';
import { Subscription } from 'rxjs';

@Component({
    selector: 'sys-station-picker[currentStation]',
    templateUrl: './station-picker.component.html',
    styleUrls: ['./station-picker.component.scss'],
})
export class StationPickerComponent implements OnInit, OnDestroy {
    @Output() stationIdSelected = new EventEmitter<Station>();
    @Input() currentStation?: Station;
    @Input() transportLocation?: TransportLocation;
    @Input() placeholder = 'Station wählen';
    @ViewChild('stationComponent')
    stationComponent!: IonicSelectableComponent;

    private subscription = new Subscription();

    stations: Station[] = [];

    constructor(private stationEntityService: StationEntityService) {}

    ngOnInit(): void {
        if (this.currentStation?.id) {
            this.stationEntityService
                .getByKey(this.currentStation.id)
                .pipe(
                    first(),
                    tap((station) => {
                        this.currentStation = station;
                    })
                )
                .subscribe();
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    setStation(): void {
        this.stationIdSelected.emit(this.currentStation);
    }

    reloadData(): void {
        if (!this.transportLocation?.id) {
            return;
        }
        this.stationEntityService
            .getWithQuery({ transportLocationId: this.transportLocation.id })
            .subscribe((stations) => (this.stations = stations));
    }
}
