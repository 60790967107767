import { Component, Inject, Input } from '@angular/core';
import {
    showEditEntityValueAlert,
    MaterialProperty,
    KeyyProperty,
    ItemType,
    API_BASE_FILE_SERVER,
    SysBaseEntityProperty,
    DeviceProperty,
} from '@sansys/crosslib';
import {
    faInfoCircle,
    faTimes,
    faEdit,
    faShapes,
    faChevronLeft,
} from '@fortawesome/pro-regular-svg-icons';
import { NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';

@Component({
    selector: 'sys-details-wrapper[type][entity]',
    templateUrl: './details-wrapper.component.html',
    styleUrls: ['./details-wrapper.component.scss'],
})
export class DetailsWrapperComponent {
    @Input() type!: ItemType;
    @Input() entity?: any;

    faTimes = faTimes;
    faEdit = faEdit;
    faInfoCircle = faInfoCircle;
    faShapes = faShapes;
    faChevronLeft = faChevronLeft;

    constructor(
        private navController: NavController,
        private store: Store<any>,
        @Inject(API_BASE_FILE_SERVER) public server: string
    ) {}

    goBack(): void {
        this.navController.back();
    }

    showUpdateAlert(
        property:
            | MaterialProperty
            | DeviceProperty
            | KeyyProperty
            | SysBaseEntityProperty
    ): void {
        this.store.dispatch(
            showEditEntityValueAlert({
                property,
                entity: this.entity,
                ligType: this.type,
            })
        );
    }

    entityHasProfileImage(): boolean {
        return (
            this.entity?.profileImage &&
            ['material', 'device', 'keyy'].includes(this.type)
        );
    }
}
