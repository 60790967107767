<ion-header>
    <ion-toolbar>
        <ion-buttons slot="secondary">
            <ion-button (click)="goBack()" color="light">
                <fa-icon
                    [icon]="faChevronLeft"
                    size="lg"
                    class="mr-8"
                ></fa-icon>
                {{ 'Zurück' | transloco }}
            </ion-button>
        </ion-buttons>
        <ion-title
            >{{ type | transloco }} {{ 'Details' | transloco }}</ion-title
        >
    </ion-toolbar>
</ion-header>

<ion-content
    [fullscreen]="true"
    class="ion-text-center ion-content__height-fix"
>
    <div
        class="quick-functions__img-container ion-margin-top"
        *ngIf="entityHasProfileImage()"
    >
        <img [src]="server + entity.profileImage" alt="" />
    </div>
    <div
        class="quick-functions__img-container ion-margin-top"
        *ngIf="!entityHasProfileImage()"
    >
        <fa-icon [icon]="faShapes" size="lg"></fa-icon>
    </div>
    <h4 *ngIf="type === 'material' || type === 'device' || type === 'keyy'">
        <span (click)="showUpdateAlert('itemNumber')">{{
            entity?.itemNumber
        }}</span>
        <span>:</span>
        <span (click)="showUpdateAlert('name')">
            {{ entity?.name }}
        </span>
    </h4>

    <ng-content select="[details-area]"></ng-content>
</ion-content>
