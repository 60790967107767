import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScannerButtonComponent } from './scanner-button/scanner-button.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicModule } from '@ionic/angular';
import { SelectLocationComponent } from './select-location/select-location.component';
import { TranslocoModule } from '@ngneat/transloco';
import { IonicSelectableModule } from 'ionic-selectable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuickFunctionsWrapperComponent } from './quick-functions-wrapper/quick-functions-wrapper.component';
import { DetailsWrapperComponent } from './details-wrapper/details-wrapper.component';
import { InputAlertComponent } from './input-alert/input-alert.component';
import { ImageDetailsComponent } from './image-details/image-details.component';
import { ImageUploaderComponent } from './image-details/image-uploader/image-uploader.component';
import { EffectsModule } from '@ngrx/effects';
import { CommonEffects } from './common.effects';
import { AddImageButtonComponent } from './add-image-button/add-image-button.component';
import { AndroidGeoModalComponent } from './android-geo-modal/android-geo-modal.component';

@NgModule({
    declarations: [
        ScannerButtonComponent,
        AddImageButtonComponent,
        SelectLocationComponent,
        QuickFunctionsWrapperComponent,
        DetailsWrapperComponent,
        InputAlertComponent,
        ImageDetailsComponent,
        ImageUploaderComponent,
        AndroidGeoModalComponent,
    ],
    imports: [
        EffectsModule.forFeature([CommonEffects]),
        CommonModule,
        FontAwesomeModule,
        IonicModule,
        TranslocoModule,
        IonicSelectableModule,
        ReactiveFormsModule,
        FormsModule,
    ],
    exports: [
        ScannerButtonComponent,
        AddImageButtonComponent,
        QuickFunctionsWrapperComponent,
        DetailsWrapperComponent,
        ImageUploaderComponent,
    ],
})
export class CustomCommonModule {}
