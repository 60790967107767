import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { IonicSelectableComponent } from 'ionic-selectable';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { first, tap } from 'rxjs/operators';
import {
    Insurance,
    Passenger,
    passengerCreated,
    PassengerEntityService,
} from '@sansys/crosslib';
import { AlertButton } from '@ionic/core/dist/types/components/alert/alert-interface';
import { AlertController } from '@ionic/angular';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subscription } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { translate } from '@ngneat/transloco';

@Component({
    selector: 'sys-passenger-picker[currentPassenger]',
    templateUrl: './passenger-picker.component.html',
    styleUrls: ['./passenger-picker.component.scss'],
})
export class PassengerPickerComponent implements OnInit, OnDestroy {
    @Output() passengerSelected = new EventEmitter<Passenger>();
    @Input() currentPassenger?: Passenger;
    @Input() placeholder = 'Eintrag wählen';
    @ViewChild('passengerComponent')
    passengerComponent!: IonicSelectableComponent;

    private subscription = new Subscription();

    healthInsurance?: Insurance;
    passengers: Passenger[] = [];

    canEdit = false;
    canAdd = false;
    canDelete = false;

    page = 1;
    loadingAmount = 20;
    amount?: number;
    dataSubscription?: Subscription;

    passengerForm: FormGroup;
    streetControl: FormControl;
    zipControl: FormControl;
    cityControl: FormControl;
    firstNameControl: FormControl;
    lastNameControl: FormControl;
    nursingHomeControl: FormControl;
    birthdayControl: FormControl;
    employerControl: FormControl;
    representativeControl: FormControl;
    repInstitutionControl: FormControl;
    repFirstNameControl: FormControl;
    repLastNameControl: FormControl;
    repStreetControl: FormControl;
    repZipControl: FormControl;
    repCityControl: FormControl;
    phoneControl: FormControl;
    phoneWorkControl: FormControl;

    constructor(
        private passengerEntityService: PassengerEntityService,
        private formBuilder: FormBuilder,
        private permissionService: NgxPermissionsService,
        private alertController: AlertController,
        private action$: Actions
    ) {
        this.firstNameControl = this.formBuilder.control(
            null,
            Validators.required
        );
        this.lastNameControl = this.formBuilder.control(
            null,
            Validators.required
        );
        this.nursingHomeControl = this.formBuilder.control(null);
        this.streetControl = this.formBuilder.control(null);
        this.zipControl = this.formBuilder.control(null);
        this.cityControl = this.formBuilder.control(null);
        this.employerControl = this.formBuilder.control(null);
        this.representativeControl = this.formBuilder.control(null);
        this.repInstitutionControl = this.formBuilder.control(null);
        this.repFirstNameControl = this.formBuilder.control(null);
        this.repLastNameControl = this.formBuilder.control(null);
        this.repStreetControl = this.formBuilder.control(null);
        this.repZipControl = this.formBuilder.control(null);
        this.repCityControl = this.formBuilder.control(null);
        this.phoneControl = this.formBuilder.control(null);
        this.phoneWorkControl = this.formBuilder.control(null);
        this.birthdayControl = this.formBuilder.control(
            null,
            Validators.required
        );
        this.passengerForm = this.formBuilder.group({
            firstName: this.firstNameControl,
            lastName: this.lastNameControl,
            nursingHome: this.nursingHomeControl,
            street: this.streetControl,
            zip: this.zipControl,
            city: this.cityControl,
            birthday: this.birthdayControl,
            employer: this.employerControl,
            representative: this.representativeControl,
            repInstitution: this.repInstitutionControl,
            repFirstName: this.repFirstNameControl,
            repLastName: this.repLastNameControl,
            repStreet: this.repStreetControl,
            repZip: this.repZipControl,
            repCity: this.repCityControl,
            phone: this.phoneControl,
            phoneWork: this.phoneWorkControl,
        });
    }

    private setPassengerAndClose(passenger: Passenger): void {
        if (this.passengerComponent.isOpened) {
            this.currentPassenger = passenger;
            this.passengerSelected.emit(passenger);
            this.passengerComponent.close();
        }
    }

    ngOnInit(): void {
        this.permissionService
            .hasPermission(['passengers|EDIT', 'admin'])
            .then((perm) => (this.canEdit = perm));
        this.permissionService
            .hasPermission(['passengers|CREATE', 'admin'])
            .then((perm) => (this.canAdd = perm));
        this.permissionService
            .hasPermission(['passengers|DELETE', 'admin'])
            .then((perm) => (this.canDelete = perm));

        if (this.currentPassenger?.id) {
            this.passengerEntityService
                .getByKey(this.currentPassenger.id)
                .pipe(
                    first(),
                    tap((passenger) => {
                        this.currentPassenger = passenger;
                    })
                )
                .subscribe();
        }

        this.subscription.add(
            this.action$
                .pipe(
                    ofType(passengerCreated),
                    tap((action) => {
                        this.passengerEntityService.addOneToCache(
                            action.passenger
                        );
                        this.setPassengerAndClose(action.passenger);
                    })
                )
                .subscribe()
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onAddPassenger(event: { component: IonicSelectableComponent }): void {
        // Clean form.
        this.firstNameControl.reset();
        this.lastNameControl.reset();
        this.nursingHomeControl.reset();
        this.streetControl.reset();
        this.zipControl.reset();
        this.cityControl.reset();
        this.birthdayControl.reset();
        this.employerControl.reset();
        this.representativeControl.reset();
        this.repInstitutionControl.reset();
        this.repFirstNameControl.reset();
        this.repLastNameControl.reset();
        this.repStreetControl.reset();
        this.repZipControl.reset();
        this.repCityControl.reset();
        this.phoneControl.reset();
        this.phoneWorkControl.reset();

        // Copy search text to passenger name field, so
        // user doesn't have to type again.
        this.firstNameControl.setValue(event.component.searchText);

        // Show form.
        event.component.showAddItemTemplate();
    }

    onEditPassenger(event: {
        component: IonicSelectableComponent;
        item: Passenger;
    }): void {
        // Fill form.
        this.firstNameControl.setValue(event.item.firstName);
        this.lastNameControl.setValue(event.item.lastName);
        this.nursingHomeControl.setValue(event.item.nursingHome);
        this.streetControl.setValue(event.item.street);
        this.zipControl.setValue(event.item.zip);
        this.cityControl.setValue(event.item.city);
        this.birthdayControl.setValue(event.item.birthday);
        this.employerControl.setValue(event.item.employer);
        this.representativeControl.setValue(event.item.representative);
        this.repInstitutionControl.setValue(event.item.repInstitution);
        this.repFirstNameControl.setValue(event.item.repFirstName);
        this.repLastNameControl.setValue(event.item.repLastName);
        this.repStreetControl.setValue(event.item.repStreet);
        this.repZipControl.setValue(event.item.repZip);
        this.repCityControl.setValue(event.item.repCity);
        this.phoneControl.setValue(event.item.phone);
        this.phoneWorkControl.setValue(event.item.phoneWork);

        // Show form.
        event.component.showAddItemTemplate();
    }

    private async askIfDuplicatePassengerIsCorrect(
        newPassenger: Passenger,
        existingPassengers: Passenger[]
    ): Promise<void> {
        const alert = await this.alertController.create({
            header: translate('Mögliche Duplikate gefunden'),
            message: translate('Verwenden oder neu anlegen?'),
            buttons: [],
        });

        const buttons: AlertButton[] = [
            {
                text: translate('Abbrechen'),
                role: 'cancel',
            },
        ];

        existingPassengers.forEach((existingPassenger) => {
            buttons.push({
                text:
                    (existingPassenger.street || '') +
                    ', ' +
                    (existingPassenger.zip || '') +
                    ' ' +
                    (existingPassenger.city || ''),
                handler: () => {
                    this.currentPassenger = existingPassenger;
                    this.passengerSelected.emit(existingPassenger);
                    this.passengerComponent.close();
                },
            });
        });

        buttons.push({
            text: translate('Neu Anlegen'),
            handler: () => {
                this.passengerEntityService
                    .add(newPassenger)
                    .subscribe((newPass) => {
                        this.setPassengerAndClose(newPass);
                    });
            },
        });

        alert.buttons = buttons;

        await alert.present();
    }

    addPassenger(): void {
        const passenger: Passenger = {
            firstName: this.firstNameControl.value,
            lastName: this.lastNameControl.value,
            nursingHome: this.nursingHomeControl.value,
            street: this.streetControl.value,
            zip: this.zipControl.value,
            city: this.cityControl.value,
            birthday: this.birthdayControl.value,
            employer: this.employerControl.value,
            representative: this.representativeControl.value,
            repInstitution: this.repInstitutionControl.value,
            repFirstName: this.repFirstNameControl.value,
            repLastName: this.repLastNameControl.value,
            repStreet: this.repStreetControl.value,
            repZip: this.repZipControl.value,
            repCity: this.repCityControl.value,
            phone: this.phoneControl.value,
            phoneWork: this.phoneWorkControl.value,
        };

        const foundPassengers: Passenger[] = [];
        this.passengers.forEach((pass) => {
            if (
                pass.firstName.toLowerCase() ===
                    passenger.firstName.toLowerCase() &&
                pass.lastName.toLowerCase() ===
                    passenger.lastName.toLowerCase() &&
                pass.birthday === passenger.birthday
            ) {
                foundPassengers.push(pass);
            }
        });

        if (foundPassengers.length > 0) {
            this.askIfDuplicatePassengerIsCorrect(passenger, foundPassengers);
            return;
        }

        this.passengerEntityService.add(passenger).subscribe((newPassenger) => {
            const pass: Passenger = JSON.parse(JSON.stringify(newPassenger));
            pass.fullName = pass.firstName + ' ' + pass.lastName;
            this.setPassengerAndClose(pass);
        });
    }

    savePassenger(passenger: Passenger): void {
        const editedPassenger = { ...passenger };
        editedPassenger.firstName = this.firstNameControl.value;
        editedPassenger.lastName = this.lastNameControl.value;
        editedPassenger.nursingHome = this.nursingHomeControl.value;
        editedPassenger.street = this.streetControl.value;
        editedPassenger.zip = this.zipControl.value;
        editedPassenger.city = this.cityControl.value;
        editedPassenger.birthday = this.birthdayControl.value;
        editedPassenger.employer = this.employerControl.value;
        editedPassenger.representative = this.representativeControl.value;
        editedPassenger.repInstitution = this.repInstitutionControl.value;
        editedPassenger.repFirstName = this.repFirstNameControl.value;
        editedPassenger.repLastName = this.repLastNameControl.value;
        editedPassenger.repStreet = this.repStreetControl.value;
        editedPassenger.repZip = this.repZipControl.value;
        editedPassenger.repCity = this.repCityControl.value;
        editedPassenger.phone = this.phoneControl.value;
        editedPassenger.phoneWork = this.phoneWorkControl.value;
        this.passengerEntityService
            .update(editedPassenger)
            .subscribe((updatedPassenger) => {
                const pass: Passenger = JSON.parse(
                    JSON.stringify(updatedPassenger)
                );
                pass.fullName = pass.firstName + ' ' + pass.lastName;
                this.searchItems({
                    component: this.passengerComponent,
                    text: pass.lastName ?? '',
                });
                this.currentPassenger = pass;
                this.setPassengerAndClose(pass);
            });
    }

    setPassenger(): void {
        this.passengerSelected.emit(this.currentPassenger);
    }

    searchItems(event?: {
        component: IonicSelectableComponent;
        text: string;
    }): void {
        let text = '';
        if (event) {
            text = event.text.trim().toLowerCase();
        }
        this.passengerComponent.startSearch();

        // Close any running subscription.
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }

        if (!text) {
            // Close any running subscription.
            if (this.dataSubscription) {
                this.dataSubscription.unsubscribe();
            }

            this.passengerEntityService
                .getWithQuery({
                    start: '0',
                    amount: this.loadingAmount.toString(),
                    search: '',
                })
                .subscribe((passengers) => {
                    const pass = passengers.map((passenger) => {
                        const p = JSON.parse(JSON.stringify(passenger));
                        p.fullName = p.firstName + ' ' + p.lastName;
                        return p;
                    });
                    this.passengers = pass;
                    this.passengerComponent.items = pass;
                    // Enable and start infinite scroll from the beginning.
                    this.page = 1;
                    this.passengerComponent.endSearch();
                    this.passengerComponent.enableInfiniteScroll();
                });

            return;
        }

        this.dataSubscription = this.passengerEntityService
            .getWithQuery({
                start: '0',
                amount: this.loadingAmount.toString(),
                search: text,
            })
            .subscribe((passengers) => {
                // Subscription will be closed when unsubscribed manually.
                if (this.dataSubscription && this.dataSubscription.closed) {
                    return;
                }

                const pass = passengers.map((passenger) => {
                    const p = JSON.parse(JSON.stringify(passenger));
                    p.fullName = p.firstName + ' ' + p.lastName;
                    return p;
                });

                this.passengers = pass;
                this.passengerComponent.items = pass;
                this.passengerComponent.endSearch();
            });
    }

    getMoreItems(event?: {
        component: IonicSelectableComponent;
        text: string;
    }): void {
        let text = '';
        if (event) {
            text = (event.text || '').trim().toLowerCase();
        }

        // There're no more transportLocations - disable infinite scroll.
        if (this.passengers.length === this.amount) {
            this.passengerComponent.disableInfiniteScroll();
            return;
        }

        this.passengerEntityService
            .getWithQuery({
                start: this.passengers.length.toString(),
                amount: this.loadingAmount.toString(),
                search: text,
            })
            .subscribe((passengers) => {
                const pass = this.passengers
                    .concat(passengers)
                    .map((passenger) => {
                        const p = JSON.parse(JSON.stringify(passenger));
                        p.fullName = p.firstName + ' ' + p.lastName;
                        return p;
                    });
                this.passengers = pass;
                this.passengerComponent.items = pass;
                this.passengerComponent.endInfiniteScroll();
                this.page++;
            });
    }
}
